import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import NoData from "app/components/CommonUI/NoData";
import LoadingFail from "app/components/CommonUI/LoadingFail";
import LoadingProgress from "app/components/CommonUI/LoadingProgress";
import CommonPagination from "app/components/CommonUI/CommonPagination";
import { getWithdrawHistory } from "app/services/api";
import { getSummaryAddress } from "../../../utils";

function WithdrawHistory(props) {
  const { reload } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loadDataState, setLoadDataState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const reloadRef = useRef();
  const gameTokenSymbol = useSelector(
    (state) => state.appConfigs?.configs?.GAME_TOKEN_SYMBOL
  );
  const configs = useSelector((state) => state.appConfigs.configs);
  const scanWebUrl = configs?.BSCSCAN_URL || "https://testnet.bscscan.com";

  const loadWithdrawHistory = useCallback(() => {
    if (reloadRef.current !== reload) {
      reloadRef.current = reload;
      if (currentPage !== 1) {
        setCurrentPage(1);
        return;
      }
    }

    setLoadDataState((oldState) => ({
      ...oldState,
      isLoading: true,
      error: null,
    }));

    getWithdrawHistory(currentPage)
      .then((res) => {
        if (Number(res.data.totalPages) > 0) setTotalPages(res.data.totalPages);

        setLoadDataState({
          isLoading: false,
          data: Array.isArray(res.data.rows)
            ? res.data.rows.map((item, index) => ({
                index:
                  Number(res.data.page) * Number(res.data.pageSize) + index,
                id: item?._id || "",
                txHash: item?.txHash || "-",
                transferAddress: item?.transferAddress || "-",
                rate: Math.round(Number(item.rate) * 100) / 100,
                diamondAmount: item.amount,
                slandAmount: item.amountReceive,
                processTime:
                  typeof item.timestamp === "number"
                    ? moment(item.timestamp * 1000).format("YYYY-MM-DD HH:ss")
                    : "-",
                createTime: moment(item.createdAt).format("YYYY-MM-DD HH:ss"),
                status: item.status,
              }))
            : [],
          error: null,
        });
      })
      .catch((error) => {
        toast.error("Load data fail!");
        setLoadDataState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [currentPage, reload]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    loadWithdrawHistory();
  }, [loadWithdrawHistory]);

  return (
    <div className="w-full h-auto py-10">
      {/* Withdraw history table */}
      <div className="w-full h-auto overflow-x-auto">
        {/* table header */}
        <div className="min-w-[920px] w-full h-[46px] border border-[#3B3B3B] rounded-[20px] pl-16 pr-5 py-3 flex items-center relative font-space_mono text-[#858584] mb-5">
          <div className="absolute top-2 left-5 w-auto h-[30px] px-2 rounded-full flex items-center justify-center">
            #
          </div>
          <div className="w-[8%] px-1">ID</div>
          <div className="w-[13%] text-center px-1">TxHash</div>
          <div className="w-[13%] text-center px-1">Transfer Address</div>
          <div className="w-1/12 text-center px-1">Rate</div>
          <div className="w-[10.335%] text-center px-1">Diamond Amount</div>
          <div className="w-[10.335%] text-center px-1">
            <span className="capitalize">
              {typeof gameTokenSymbol === "string"
                ? gameTokenSymbol.toLowerCase()
                : ""}
            </span>{" "}
            Amount
          </div>
          <div className="w-[14.335%] text-center px-1">Process Time</div>
          <div className="w-[14.335%] text-center px-1">Create Time</div>
          <div className="w-1/12 text-end px-1">Status</div>
        </div>

        {/* table body */}

        {loadDataState.isLoading ? (
          <div className="w-full h-[244px] rounded-[20px] overflow-auto mb-5 custom-scrollbar">
            <LoadingProgress />
          </div>
        ) : null}

        {!loadDataState.isLoading && loadDataState.error ? (
          <div className="w-full h-[244px] rounded-[20px] overflow-auto mb-5 custom-scrollbar">
            <LoadingFail
              error={loadDataState.error}
              errorHandling={loadWithdrawHistory}
            />
          </div>
        ) : null}

        {!loadDataState.isLoading &&
        !loadDataState.error &&
        Array.isArray(loadDataState.data) ? (
          <div className="min-w-[1024px] w-full h-auto max-h-[244px] rounded-[20px] overflow-auto mb-5 custom-scrollbar">
            {loadDataState.data.length > 0 ? (
              loadDataState.data.map((item, index) => (
                <div
                  key={item.id}
                  className="w-full h-[46px] bg-[#3B3B3B] rounded-[20px] pl-16 pr-5 py-3 flex items-center relative font-space_mono text-white mb-5"
                >
                  <div className="absolute top-2 left-5 w-auto h-[30px] min-w-[30px] px-2 rounded-full flex items-center justify-center bg-[#2B2B2B]">
                    {item.index + 1}
                  </div>
                  <div className="w-[10%] text-start px-1">
                    <Tooltip title={item.id}>
                      <p className="w-full overflow-hidden truncate pr-2">
                        {item.id}
                      </p>
                    </Tooltip>
                  </div>
                  <div className="w-[13%] text-center px-1 text-sm">
                    {item.txHash === "-" ? (
                      <span> - </span>
                    ) : (
                      <a
                        href={`${scanWebUrl}/tx/${item.txHash}`}
                        className="text-blue-500 underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getSummaryAddress(item.txHash)}
                      </a>
                    )}
                  </div>
                  <div className="w-[13%] text-center px-1 text-sm">
                    {item.transferAddress === "-" ? (
                      <span> - </span>
                    ) : (
                      <a
                        href={`${scanWebUrl}/address/${item.transferAddress}`}
                        className="text-blue-500 underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getSummaryAddress(item.transferAddress)}
                      </a>
                    )}
                  </div>
                  <div className="w-1/12 text-center px-1">{item.rate}</div>
                  <div className="w-[12.335%] text-center px-1">
                    {item.diamondAmount}
                  </div>
                  <div className="w-[12.335%] text-center px-1">
                    {item.slandAmount}
                  </div>
                  <div className="w-[14.335%] text-center px-1">
                    {item.processTime}
                  </div>
                  <div className="w-[14.335%] text-center px-1">
                    {item.createTime}
                  </div>
                  <div className="w-1/12 text-end px-1">
                    {item.status === "done" ? (
                      <span className="text-[#02AB46]">Success</span>
                    ) : null}
                    {item.status === "pending" ? (
                      <span className="text-[#eff86d]">Pending</span>
                    ) : null}
                    {item.status === "fail" ? (
                      <span className="text-[#fa5e5e]">Fail</span>
                    ) : null}
                  </div>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        ) : null}
      </div>

      {totalPages > 0 ? (
        <div className="w-full h-auto flex item-center justify-center">
          <CommonPagination
            key={reload}
            // defaultPage={currentPage}
            onChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      ) : null}
    </div>
  );
}

export default WithdrawHistory;
