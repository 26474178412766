import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingProgress from "app/components/CommonUI/LoadingProgress";
import LoadingFail from "app/components/CommonUI/LoadingFail";
import NoData from "app/components/CommonUI/NoData";
import TowerCard from "app/components/Card/CardTower";
import { getRelatedItems } from "app/services/api";

function RelatedTower(props) {
  const { id } = props;

  const [loadDataState, setLoadDataState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadDataState({
      isLoading: true,
      data: null,
      error: null,
    });

    getRelatedItems(id)
      .then((res) => {
        setLoadDataState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadDataState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [id]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <div className="w-full h-auto">
      <div className="flex">
        <h2 className="w-auto h-[60px] border-b-2 border-[#00B800] flex items-center justify-center mb-5">
          <span className="font-work_sans text-[22px] leading-8 font-semibold">
            Related Results
          </span>
        </h2>
      </div>

      <div className="w-full h-auto">
        {loadDataState.isLoading ? <LoadingProgress /> : null}

        {!loadDataState.isLoading && loadDataState.error ? (
          <LoadingFail error={loadDataState.error} errorHandling={() => {}} />
        ) : null}

        {!loadDataState.isLoading &&
        !loadDataState.error &&
        Array.isArray(loadDataState.data) ? (
          loadDataState.data.length > 0 ? (
            <div className="w-full h-auto flex justify-center flex-wrap gap-[30px] mb-[30px]">
              {loadDataState.data.slice(0, 4).map((item) => (
                <TowerCard key={item._id} towerInfo={item} price={item?.nft_listing?.price} />
              ))}
            </div>
          ) : (
            <NoData />
          )
        ) : null}
      </div>
    </div>
  );
}

export default RelatedTower;
