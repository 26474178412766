import React, { useCallback, useEffect, useState } from "react";
import CommonPagination from "app/components/CommonUI/CommonPagination";
import { toast } from "react-toastify";
import NoData from "app/components/CommonUI/NoData";
import LoadingFail from "app/components/CommonUI/LoadingFail";
import LoadingProgress from "app/components/CommonUI/LoadingProgress";
import { getSaleHistory } from "app/services/api";
import { getSummaryAddress, paddedToChecksumAddress } from "../../utils";
import moment from "moment";

function SaleHistory(props) {
  const { towerId, towerIndex } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loadDataState, setLoadDataState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadSaleHistory = useCallback(
    (page) => {
      setLoadDataState((oldState) => ({
        ...oldState,
        isLoading: true,
        error: null,
      }));

      getSaleHistory(towerIndex, page)
        .then((res) => {
          if (Number(res.data.totalPages) > 0)
            setTotalPages(res.data.totalPages);

          setLoadDataState({
            isLoading: false,
            data: Array.isArray(res.data.rows) ? res.data.rows : [],
            error: null,
          });
        })
        .catch((error) => {
          toast.error("Load data fail!");
          setLoadDataState({
            isLoading: false,
            data: null,
            error: error,
          });
        });
    },
    [towerIndex]
  );

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    loadSaleHistory(page);
  };

  useEffect(() => {
    loadSaleHistory(currentPage);
  }, []);

  return (
    <div className="w-full h-auto py-5">
      {/* Withdraw history table */}
      <div className="">
        {/* table header */}
        <div className="w-full h-[46px] border border-[#3B3B3B] rounded-[20px] pr-5 py-3 flex items-center relative font-space_mono text-[#858584] mb-5">
          <div className="w-[120px] text-center">Price</div>
          <div className="w-[150px] text-center">Date</div>
          <div className="w-[calc(100%-270px)] flex items-center">
            <div className="w-1/2 text-center">Owner</div>
            <div className="w-1/2 text-center">Buyer</div>
          </div>
        </div>

        {/* table body */}

        {loadDataState.isLoading ? (
          <div className="w-full h-[244px] rounded-[20px] overflow-auto mb-5 custom-scrollbar">
            <LoadingProgress />
          </div>
        ) : null}

        {!loadDataState.isLoading && loadDataState.error ? (
          <div className="w-full h-[244px] rounded-[20px] overflow-auto mb-5 custom-scrollbar">
            <LoadingFail
              error={loadDataState.error}
              errorHandling={loadSaleHistory}
            />
          </div>
        ) : null}

        {!loadDataState.isLoading &&
        !loadDataState.error &&
        Array.isArray(loadDataState.data) ? (
          <div className="w-full h-auto max-h-[244px] rounded-[20px] overflow-auto mb-5 custom-scrollbar">
            {loadDataState.data.length > 0 ? (
              loadDataState.data.map((item, index) => (
                <div
                  key={item.id}
                  className="w-full h-[46px] bg-[#3B3B3B] rounded-[20px] pr-5 py-3 flex items-center relative font-space_mono text-white mb-5"
                >
                  <div className="w-[120px] text-center">{`${item.amount} ${item.currencySymbol}`}</div>
                  <div className="w-[150px] text-center">{item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD HH:mm") : ""}</div>
                  <div className="w-[calc(100%-270px)] flex items-center">
                    <div className="w-1/2 text-center">
                      {getSummaryAddress(paddedToChecksumAddress(item.topic1), 5)}
                    </div>
                    <div className="w-1/2 text-center">
                      {getSummaryAddress(paddedToChecksumAddress(item.topic2), 5)}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        ) : null}
      </div>

      {totalPages > 0 ? (
        <div className="w-full h-auto flex item-center justify-center">
          <CommonPagination
            onChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      ) : null}
    </div>
  );
}

export default SaleHistory;
