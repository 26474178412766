import React from "react";
import { Dialog } from "@mui/material";
import { Zoom } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function CommonAuthDialog(props) {
  const { open, handleClose, children, padding, width, ...rest } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: "backdrop-blur-sm",
      }}
      PaperProps={{
        style: {
          backgroundColor: "#2b2b2b",
          padding: padding || "16px",
          width: width || "360px",
          borderRadius: "12px",
          position: "relative",
        },
      }}
      TransitionComponent={Zoom}
      {...rest}
    >
      <button
        type="button"
        onClick={handleClose}
        className="absolute top-4 right-4 w-7 h-7 rounded-full flex items-center justify-center border-2 border-solid border-gray-200 text-gray-200 hover:border-white hover:text-white"
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>
      {children}
    </Dialog>
  );
}

export default CommonAuthDialog;
