import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { defaultTowerImage } from "app/constants";
import routePaths from "app/route/Path";
import starIcon from "app/assets/svg/tower_star_icon.svg";
import damageIcon from "app/assets/svg/statIcon/damage.svg";
import fireRateIcon from "app/assets/svg/statIcon/fireRate.svg";
import slowSpeedIcon from "app/assets/svg/statIcon/slowSpeed.svg";
import speedIcon from "app/assets/svg/statIcon/speed.svg";

function CardTower(props) {
  const { towerInfo, price } = props;

  const towerInfoSummary = useMemo(() => {
    const result = {
      id: towerInfo._id,
      index: towerInfo.index,
      name: towerInfo.name,
      image: defaultTowerImage,
      fire: 0,
      damage: 0,
      slow_speed: 0,
      speed: 0,
    };

    const asset = towerInfo.asset;
    result.image = asset?.url || defaultTowerImage;

    if (Array.isArray(towerInfo.levels) && towerInfo.levels.length > 0) {
      const towerInfoLevel0 = towerInfo.levels[0];
      result.fire = Number(towerInfoLevel0.fire_rate);
      result.damage = Number(towerInfoLevel0.damage);
      result.slow_speed = Number(towerInfoLevel0.slow_speed);
      result.speed = Number(towerInfoLevel0.speed);
    }

    return result;
  }, [towerInfo]);

  const gameTokenSymbol = useSelector(
    (state) => state.appConfigs?.configs?.GAME_TOKEN_SYMBOL
  );

  return (
    <Link
      to={routePaths.towerDetail(towerInfo.index)}
      className="w-[294px] h-auto rounded-[20px] bg-[#3B3B3B] cursor-pointer transition-all hover:shadow-md hover:shadow-[#ffffff66]"
    >
      {/* card image */}
      <div className="w-full pt-[73.5%] relative">
        <div className="absolute inset-0 w-full rounded-t-[20px] bg-[#C4C4C4]">
          <img
            alt=""
            src={towerInfoSummary.image}
            className="object-contain w-full h-full"
          />
        </div>
      </div>

      {/* card info */}
      <div className="w-full h-auto px-7 pt-2.5 pb-6">
        <h5 className="w-full truncate text-[22px] leading-[30px] font-semibold mb-2.5">
          <span>{towerInfoSummary.name}</span>{" "}
          <span className="text-gray-300">#{towerInfoSummary.index}</span>
        </h5>

        <div className="w-full h-auto flex items-center justify-start flex-wrap mb-2.5">
          <img alt="" className="w-6 h-6 mb-2" src={starIcon} />
        </div>

        <div className="w-full h-auto flex mb-[15px] font-space_mono">
          <div className="w-1/2">
            <div className="w-full h-auto flex items-center gap-1.5 mb-1">
              <img alt="" className="w-[18px] h-[18px]" src={damageIcon} />
              <span className="text-xs leading-[13px] text-[#858584]">
                Damage
              </span>
            </div>

            <p className="text-sm leading-[20px] text-white">
              {towerInfoSummary.damage}
            </p>
          </div>
          <div className="w-1/2">
            <div className="w-full h-auto flex items-center gap-1.5 mb-1">
              <img alt="" className="w-[18px] h-[18px]" src={speedIcon} />
              <span className="text-xs leading-[13px] text-[#858584]">
                Speed
              </span>
            </div>

            <p className="text-sm leading-[20px] text-white">
              {towerInfoSummary.speed}
            </p>
          </div>
        </div>

        <div className="w-full h-auto flex font-space_mono">
          <div className="w-1/2">
            <div className="w-full h-auto flex items-center gap-1.5 mb-1">
              <img alt="" className="w-[18px] h-[18px]" src={slowSpeedIcon} />
              <span className="text-xs leading-[13px] text-[#858584]">
                Slow Speed
              </span>
            </div>

            <p className="text-sm leading-[20px] text-white">
              {towerInfoSummary.slow_speed}
            </p>
          </div>
          <div className="w-1/2">
            <div className="w-full h-auto flex items-center gap-1.5 mb-1">
              <img alt="" className="w-[18px] h-[18px]" src={fireRateIcon} />
              <span className="text-xs leading-[13px] text-[#858584]">
                Fire Rate
              </span>
            </div>

            <p className="text-sm leading-[20px] text-white">
              {towerInfoSummary.fire}
            </p>
          </div>
        </div>

        {price ? (
          <div className="w-full h-auto font-space_mono mt-2.5">
            <p className="text-xs text-[#00B800] mb-2">Current Price</p>

            <p className="text-base text-white">
              <span>{price}</span> <span>{gameTokenSymbol}</span>
            </p>
          </div>
        ) : null}
      </div>
    </Link>
  );
}

export default CardTower;
