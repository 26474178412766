import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import Path from "app/route/Path";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyEmail } from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function VerifyEmail() {
  const [tokenStatus, setTokenStatus] = useState("checking");

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleCheckToken = useCallback(() => {
    if (!token) {
      setTokenStatus("invalid");
      toast.error(
        <div className="flex flex-col">
          <p className="font-bold mb-1">Invalid token!</p>
        </div>
      );
      return;
    }

    setTokenStatus("checking");

    verifyEmail(token)
      .then((res) => {
        if (res.data.status === 200) {
          setTokenStatus("valid");
        } else {
          setTokenStatus("invalid");
          toast.error(
            <div className="flex flex-col">
              <p className="font-bold mb-1">{res.data.msg}</p>
            </div>
          );
        }
      })
      .catch(() => {
        setTokenStatus("invalid");
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Invalid token!</p>
          </div>
        );
      });
  }, [token]);

  useEffect(() => {
    handleCheckToken();
  }, [handleCheckToken]);

  return (
    <>
      <div className="w-full h-full min-h-screen flex items-center justify-center bg-[#222222]">
        <div className="w-full h-auto max-w-sm p-6 rounded-xl bg-[#2b2b2b] flex flex-col items-center justify-center">
          {tokenStatus === "checking" ? (
            <div className="flex flex-col items-center justify-center py-12 ">
              <CircularProgress />
            </div>
          ) : null}
          {tokenStatus === "invalid" ? (
            <div className="w-full h-auto py-12 px-4 flex flex-col items-center justify-center">
              <div className="w-20 h-20 flex items-center justify-center text-orange-500 mb-3">
                <FontAwesomeIcon icon={faTriangleExclamation} size="3x" />
              </div>

              <h3 className="w-full text-xl font-bold text-center text-white mb-6">
                Token verification failed!
              </h3>

              <Link
                to={Path.home}
                className="w-full h-10 flex items-center justify-center rounded-full transition-all bg-[#888888] hover:bg-gray-400"
              >
                <span className="text-base font-semibold text-white">
                  Return home
                </span>
              </Link>
            </div>
          ) : null}

          {tokenStatus === "valid" ? (
            <div className="w-full h-auto py-12 px-4 flex flex-col items-center justify-center">
              <div className="w-20 h-20 flex items-center justify-center text-green-500 mb-3">
                <FontAwesomeIcon icon={faCircleCheck} size="3x" />
              </div>

              <h3 className="w-full text-xl font-bold text-center text-white mb-6">
                Verify token successful
              </h3>

              <Link
                to={Path.home}
                className="w-full h-10 flex items-center justify-center rounded-full transition-all bg-[#888888] hover:bg-gray-400"
              >
                <span className="text-base font-semibold text-white">
                  Login now
                </span>
              </Link>
            </div>
          ) : null}
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default VerifyEmail;
