import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import LoadingBackdrop from "app/components/CommonUI/LoadingBackdrop";
import { ConfirmLinkWallet } from "app/components/LoggedInMenu";
import { linkWallet } from "app/services/api";
import { setLinkedWalletInfo } from "app/redux/authReducer";

function LinkWalletButton() {
  const [confirmLinkWallet, setConfirmLinkWallet] = useState(false);
  const [isCallingApi, setIsCallingApi] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );

  const handleLinkWallet = () => {
    if (!connectedWalletInfo || !connectedWalletInfo.address) {
      toast.error(
        <div className="flex flex-col">
          <p className="font-bold mb-1">Error</p>
          <p>Please connect your wallet first!</p>
        </div>
      );
      return;
    }

    setIsCallingApi(true);
    linkWallet(connectedWalletInfo.address)
      .then((res) => {
        dispatch(setLinkedWalletInfo({ address: connectedWalletInfo.address }));
        setConfirmLinkWallet(false);
        toast.success(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Success</p>
            <p>Link wallet successful!</p>
          </div>
        );
      })
      .catch((error) => {
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Error</p>
            <p>Link wallet failed!</p>
          </div>
        );
      })
      .finally(() => setIsCallingApi(false));
  };

  const handleClickLinkWallet = () => {
    if (!connectedWalletInfo) {
      toast.error(
        <div className="flex flex-col">
          <p className="font-bold mb-1">Error</p>
          <p>Please connect your wallet first!</p>
        </div>
      );
      return;
    }

    setConfirmLinkWallet(true);
  };

  return (
    <>
      <button
        type="button"
        onClick={handleClickLinkWallet}
        className="w-auto h-12 px-6 rounded-full flex items-center justify-center text-white bg-green-main ml-3"
      >
        <span className="text-base leading-4 font-semibold">Link Wallet</span>

        <div className="w-8 h-8 flex items-center justify-center ml-2">
          <FontAwesomeIcon icon={faWallet} size="lg" />
        </div>
      </button>

      <LoadingBackdrop open={isCallingApi} />

      <ConfirmLinkWallet
        open={Boolean(confirmLinkWallet && connectedWalletInfo && user)}
        handleClose={() => setConfirmLinkWallet(false)}
        handleLinkWallet={handleLinkWallet}
      />
    </>
  );
}

export default LinkWalletButton;
