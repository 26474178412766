import { inventoryType } from "../../constants";
import client from "./client";
const defaultPerPage = 12;

const fakeResponseDataApi = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: responseData,
      });
    }, 1000);
  });
};

export const login = (email, password) => {
  return client.post("/auth/login", { email, password });
};

export const register = (email, password) => {
  return client.post("/auth/register", { email, password });
};

export const getUserProfile = () => {
  return client.get(`/user/profile`);
};

export const sendMailResetPassword = (email) => {
  return client.post(`/auth/sendForgot`, { email: email });
};

export const unlinkCurrentWallet = () => {
  return client.post(`/user/connect-metamask`, { address: "" });
};

export const linkWallet = (walletAddress) => {
  return client.post(`/user/connect-metamask`, { address: walletAddress });
};

// MARKETPLACE
export const getAllListingItems = (params) => {
  return client.get("/market_place/get-all-items-listing", {
    params: {
      page: params.page,
      perPage: defaultPerPage,
      nft_id: params?.search || "",
      nft_type: params?.type || "",
      sort:
        params?.sortBy === "price_desc"
          ? 3
          : params?.sortBy === "price_esc"
          ? 2
          : 1,
    },
  });
};

// INVENTORY
export const getInWalletInventory = (connectedWalletInfoAddress, page) => {
  return client.get("/market_place/inventory-get-with-address", {
    params: {
      page: page,
      perPage: defaultPerPage,
      address: connectedWalletInfoAddress,
    },
  });
};

export const getInGameInventory = (page, perPage = defaultPerPage) => {
  return client.get("/market_place/inventory", {
    params: {
      page: page,
      perPage: perPage,
      type: inventoryType.inGame,
    },
  });
};

export const moveTowerIntoGame = (towerId) => {
  return client.post("/user/add-tower-to-game", {
    towers: [towerId],
  });
};

export const moveTowerToLinkedWallet = (towerId) => {
  return client.post("/user/withdraw-tower-to-wallet", {
    towers: [towerId],
  });
};

// WALLET WITHDRAW
export const getWithdrawHistory = (page) => {
  return client.get("/user/withdraw-list", {
    params: {
      page: Number(page || 1) - 1, // page start from 0
      perPage: defaultPerPage,
    },
  });
};

export const loadWithdrawSettings = () => {
  return client.get(`/config`).then((res) => {
    const transferSetting = res?.data?.data?.find(
      (item) => item?.name === "transfer_setting"
    );
    if (transferSetting)
      return {
        data: {
          diamondToBitRate: transferSetting?.value?.rate,
          feeRate: 0,
          minDiamond: transferSetting?.value?.min,
        },
      };
    throw Error("Load config fail!");
  });
};

export const postWithdrawBit = async (amount) => {
  const res = await client.post("/user/withdraw-diamond", {
    amount: amount,
  });

  if (res.data?.code === 400) throw new Error(res?.data.msg);
  return res;
};

//
export const getDetailTower = (index) => {
  return client.get(`/tower/info/${index}`).then((res) => {
    return {
      data: {
        id: res.data?._id,
        ...res.data,
      },
    };
  });
};
// /sale/get-sale-with-address
export const getSaleHistory = (towerId, page) => {
  return client.post("/sale/get-sale-with-address", {
    page: `${page}`,
    pageSize: `${defaultPerPage}`,
    nft_id: `${towerId}`,
  });
};

export const moveNftToWallet = (towerId, walletAddress) => {
  return fakeResponseDataApi({
    success: true,
  });
};

export const getRelatedItems = (towerId) => {
  return client.get("/tower/releated", {
    params: {
      id: towerId,
    },
  });
};

export const resetPassword = (token, newPassword) => {
  return client.get(`/auth/forgot`, {
    params: {
      token: token,
      password: newPassword,
    },
  });
};

export const verifyToken = (token) => {
  return client.get(`/auth/verify`, {
    params: {
      token: token,
    },
  });
};

export const verifyEmail = (token) => {
  return client.get(`/auth/emailVerify?`, {
    params: {
      token: token,
    },
  });
};
