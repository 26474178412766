import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadSuccess: false,
  configs: {},
};

const slice = createSlice({
  name: "appConfigs",
  initialState,
  reducers: {
    handleLoadConfigsSuccess: (state, action) => {
      state.isLoadSuccess = true;
      state.configs = action.payload;
    },
  },
});

export const { handleLoadConfigsSuccess } = slice.actions;

export default slice.reducer;
