import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import {
  faCircleCheck,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import createAccountBg from "app/assets/images/background/create_account_bg.png";
import CommonAuthDialog from "./CommonAuthDialog";
import InputPasswordField from "./InputPasswordField";
import { callApiStatus } from "../../constants";
import { capitalizeFirstLetter } from "../../utils";
import { register } from "../../services/api";

function RegisterWithEmailDialog(props) {
  const { open, handleCloseRegister, handleCloseAuthDialog } = props;

  const [registerData, setRegisterData] = useState({
    email: "",
    password: "",
    confirm_password: "",
    confirm_terms: false,
  });

  const [registerDataError, setRegisterDataError] = useState({
    confirm_password: "",
    confirm_terms: "",
  });

  const [registerStatus, setRegisterStatus] = useState(callApiStatus.idle);
  const [resCode, setResCode] = useState(null);
  const [resMsg, setResMsg] = useState("Unexpected error");

  const resetState = () => {
    setRegisterData({
      email: "",
      password: "",
      confirm_password: "",
      confirm_terms: false,
    });
    setTimeout(() => setRegisterStatus(callApiStatus.idle), 300);
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const checkValidData = () => {
    const newError = {
      password: "",
      confirm_password: "",
      confirm_terms: "",
    };
    if (registerData.password.length < 8) {
      newError.password = "Please enter at least 8 characters!";
    }

    if (registerData.password !== registerData.confirm_password) {
      newError.confirm_password = "Password must match confirm password!";
    }

    if (!registerData.confirm_terms) {
      newError.confirm_terms = "Please read the terms of use";
    }

    setRegisterDataError(newError);
    return (
      !newError.password &&
      !newError.confirm_password &&
      !newError.confirm_terms
    );
  };

  const handleRegister = () => {
    if (registerStatus === callApiStatus.loading) return "";
    if (!checkValidData()) return;

    setRegisterStatus(callApiStatus.loading);

    register(registerData.email, registerData.password)
      .then((res) => {
        setResCode(res.data.error);
        setResMsg(res.data.msg);
        if (res.data.error === 200) {
          setRegisterStatus(callApiStatus.success);
        } else {
          setRegisterStatus(callApiStatus.error);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setRegisterStatus(callApiStatus.error);
      });
  };

  const handleResetPopupResponse = () => {
    setRegisterStatus(callApiStatus.idle);
    handleCloseRegister();
  };

  const handleReturnHome = () => {
    handleCloseRegister();
    handleCloseAuthDialog();
  };

  function RenderPopupResponse({ errorCode }) {
    switch (errorCode) {
      case 200:
        return (
          <div className="w-full h-auto py-6 px-4 flex flex-col items-center justify-center">
            <div className="w-20 h-20 flex items-center justify-center text-green-500 mb-3">
              <FontAwesomeIcon icon={faCircleCheck} size="3x" />
            </div>

            <h3 className="w-full text-xl font-bold text-center text-white mb-4">
              Please click on the link in the email to verify your account
            </h3>

            <button
              type="button"
              onClick={() => handleReturnHome()}
              className="w-full h-[46px] flex items-center justify-center text-base leading-[22px] text-white rounded-full bg-purple-600 hover:bg-purple-500 mb-4"
            >
              <span className="text-base font-semibold text-white">
                Return home
              </span>
            </button>
          </div>
        );
      default:
        return (
          <div className="w-full h-auto py-4 px-4 flex flex-col items-center justify-center">
            <div className="w-20 h-20 flex items-center justify-center text-red-500 mb-1">
              <FontAwesomeIcon icon={faXmarkCircle} size="3x" />
            </div>

            <h3 className="w-full text-xl font-bold text-center text-white mb-4">
              {capitalizeFirstLetter(resMsg)}
            </h3>
            <button
              type="button"
              onClick={() => handleResetPopupResponse()}
              className="w-full h-[46px] flex items-center justify-center text-base leading-[22px] text-white rounded-full bg-purple-600 hover:bg-purple-500 mb-4"
            >
              <span className="text-base font-semibold text-white">Back</span>
            </button>
          </div>
        );
    }
  }

  return (
    <>
      <CommonAuthDialog
        open={open}
        handleClose={handleCloseRegister}
        padding="0px"
        width="auto%"
        maxWidth="md"
      >
        <>
          {/* Mobile Form */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleRegister();
            }}
            className="w-full h-auto flex flex-col items-center justify-center md:hidden"
          >
            <img
              src={createAccountBg}
              alt="Create Account Background"
              className="w-full h-60 object-cover object-center mb-4"
            />
            <div className="px-4">
              <h1 className="text-[38px] leading-[38px] font-semibold text-white text-center">
                Create Account
              </h1>
              <p className="text-base leading-[22px] text-white text-center font-normal block my-6">
                Welcome! Enter your details and start creating, collecting and
                selling NFTs
              </p>
              <div className="w-full h-auto mt-4">
                <div className="w-full h-auto relative">
                  <span className="absolute left-2 top-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-gray-500"
                    />
                  </span>
                  <input
                    type="email"
                    value={registerData.email}
                    required
                    onChange={(e) => {
                      setRegisterData((oldState) => ({
                        ...oldState,
                        email: e.target.value,
                      }));
                    }}
                    placeholder="Email"
                    className="w-full h-[46px] rounded-full pl-10 pr-5 py-3 text-base leading-[22px] bg-white text-gray-900"
                  />
                </div>
              </div>
              <div className="w-full h-auto mt-4">
                <InputPasswordField
                  value={registerData.password}
                  required
                  onChange={(e) => {
                    setRegisterData((oldState) => ({
                      ...oldState,
                      password: e.target.value,
                    }));
                    setRegisterDataError((oldState) => ({
                      ...oldState,
                      password: "",
                    }));
                  }}
                  placeholder="Password"
                />
                {registerDataError.password ? (
                  <div className="text-sm text-red-600 inline-flex items-center mt-2 ml-2">
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    <span className="ml-1">{registerDataError.password}</span>
                  </div>
                ) : null}
              </div>
              <div className="w-full h-auto mt-4">
                <InputPasswordField
                  value={registerData.confirm_password}
                  required
                  onChange={(e) => {
                    setRegisterData((oldState) => ({
                      ...oldState,
                      confirm_password: e.target.value,
                    }));
                    setRegisterDataError((oldState) => ({
                      ...oldState,
                      confirm_password: "",
                    }));
                  }}
                  placeholder="Confirm Password"
                />
                {registerDataError.confirm_password ? (
                  <div className="text-sm text-red-600 inline-flex items-center mt-2 ml-2">
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    <span className="ml-1">
                      {registerDataError.confirm_password}
                    </span>
                  </div>
                ) : null}
              </div>

              <div className="w-full h-auto mt-4 mb-3">
                <div className="w-full h-auto flex justify-center items-center">
                  <p className="text-gray-200 inline-flex items-center">
                    <input
                      type="checkbox"
                      id="confirm_terms_mobile_form"
                      className="w-4 h-4 mr-2"
                      checked={registerData.confirm_terms}
                      onChange={(e) => {
                        setRegisterData((oldState) => ({
                          ...oldState,
                          confirm_terms: !oldState.confirm_terms,
                        }));
                        setRegisterDataError((oldState) => ({
                          ...oldState,
                          confirm_terms: "",
                        }));
                      }}
                    />

                    <label htmlFor="confirm_terms_mobile_form">
                      I have read and agree
                    </label>
                  </p>
                  <button
                    type="button"
                    className="text-gray-200 hover:text-gray-50 transition-all ml-1"
                  >
                    (Terms of use)
                  </button>
                </div>
                <div className="w-full h-auto flex justify-center items-center">
                  {registerDataError.confirm_terms ? (
                    <div className="text-sm text-red-600 inline-flex items-center mt-1">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      <span className="ml-1">
                        {registerDataError.confirm_terms}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                className="w-full h-[46px] flex items-center justify-center text-base leading-[22px] text-white rounded-full bg-purple-600 hover:bg-purple-500 mb-4"
              >
                Register
              </button>
            </div>
          </form>

          {/* PC Form */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleRegister();
            }}
            className="hidden md:flex w-full h-auto"
          >
            <div className="flex-1">
              <img
                src={createAccountBg}
                alt="Create Account Background"
                // className="w-1/2 object-cover object-center"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex-1">
              <div className="m-10">
                <h1 className="text-[38px] leading-[38px] font-semibold text-white">
                  Create Account
                </h1>
                <p className="text-base leading-[22px] text-white font-normal block my-6">
                  Welcome! Enter your details and start creating, collecting and
                  selling NFTs
                </p>
                <div className="w-full h-auto mt-4">
                  <div className="w-full h-auto relative">
                    <span className="absolute left-2 top-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="text-gray-500"
                      />
                    </span>
                    <input
                      type="email"
                      value={registerData.email}
                      required
                      onChange={(e) => {
                        setRegisterData((oldState) => ({
                          ...oldState,
                          email: e.target.value,
                        }));
                      }}
                      placeholder="Email"
                      className="w-full h-[46px] rounded-full pl-10 pr-5 py-3 text-base leading-[22px] bg-white text-gray-900"
                    />
                  </div>
                </div>
                <div className="w-full h-auto mt-4">
                  <InputPasswordField
                    value={registerData.password}
                    required
                    onChange={(e) => {
                      setRegisterData((oldState) => ({
                        ...oldState,
                        password: e.target.value,
                      }));
                      setRegisterDataError((oldState) => ({
                        ...oldState,
                        password: "",
                      }));
                    }}
                    placeholder="Password"
                  />
                  {registerDataError.password ? (
                    <div className="text-sm text-red-600 inline-flex items-center mt-2 ml-1">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      <span className="ml-1">{registerDataError.password}</span>
                    </div>
                  ) : null}
                </div>
                <div className="w-full h-auto mt-4">
                  <InputPasswordField
                    value={registerData.confirm_password}
                    required
                    onChange={(e) => {
                      setRegisterData((oldState) => ({
                        ...oldState,
                        confirm_password: e.target.value,
                      }));
                      setRegisterDataError((oldState) => ({
                        ...oldState,
                        confirm_password: "",
                      }));
                    }}
                    placeholder="Confirm Password"
                  />
                  {registerDataError.confirm_password ? (
                    <div className="text-sm text-red-600 inline-flex items-center mt-2 ml-1">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      <span className="ml-1">
                        {registerDataError.confirm_password}
                      </span>
                    </div>
                  ) : null}
                </div>

                <div className="w-full h-auto mt-4 mb-3">
                  <div className="w-full h-auto flex justify-center items-center">
                    <p className="text-gray-200 inline-flex items-center">
                      <input
                        type="checkbox"
                        id="confirm_terms_pc_form"
                        className="w-4 h-4 mr-2"
                        checked={registerData.confirm_terms}
                        onChange={(e) => {
                          setRegisterData((oldState) => ({
                            ...oldState,
                            confirm_terms: !oldState.confirm_terms,
                          }));
                          setRegisterDataError((oldState) => ({
                            ...oldState,
                            confirm_terms: "",
                          }));
                        }}
                      />

                      <label htmlFor="confirm_terms_pc_form">
                        I have read and agree
                      </label>
                    </p>
                    <button
                      type="button"
                      className="text-gray-200 hover:text-gray-50 transition-all ml-1"
                    >
                      (Terms of use)
                    </button>
                  </div>
                  <div className="w-full h-auto flex justify-center items-center">
                    {registerDataError.confirm_terms ? (
                      <div className="text-sm text-red-600 inline-flex items-center mt-1">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        <span className="ml-1">
                          {registerDataError.confirm_terms}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full h-[46px] flex items-center justify-center text-base leading-[22px] text-white rounded-full bg-purple-600 hover:bg-purple-500 mb-4"
                >
                  Register
                </button>
              </div>
            </div>
          </form>
        </>
      </CommonAuthDialog>

      <Dialog
        open={
          registerStatus !== callApiStatus.idle &&
          registerStatus !== callApiStatus.loading
        }
        onClose={handleResetPopupResponse}
        classes={{
          root: "backdrop-blur-sm",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#2b2b2b",
            padding: "16px",
            width: "360px",
            borderRadius: "12px",
            position: "relative",
          },
        }}
      >
        {<RenderPopupResponse errorCode={resCode} resMsg={resMsg} />}
      </Dialog>
    </>
  );
}

export default RegisterWithEmailDialog;
