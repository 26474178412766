import { CircularProgress, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { checkAddressIsSame } from "../../../utils";
import { withdrawNFT } from "../../../services/web3Services";

function MoveNftToWallet(props) {
  const { nftToken, moveNftToWalletSuccess } = props;
  const user = useSelector((state) => state.auth.user);
  const hasLoggedIn = useSelector((state) => state.auth.token?.accessToken);
  const linkedWalletInfo = useSelector((state) => state.auth.linkedWalletInfo);
  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );

  const [isMovingToWallet, setIsMovingToWallet] = useState(false);

  const disabledBtn = useMemo(() => {
    return (
      !user ||
      !hasLoggedIn ||
      !linkedWalletInfo?.address ||
      !connectedWalletInfo?.address ||
      !checkAddressIsSame(
        linkedWalletInfo?.address,
        connectedWalletInfo?.address
      )
    );
  }, [user, hasLoggedIn, linkedWalletInfo, connectedWalletInfo]);

  const tooltipTitle = useMemo(() => {
    if (!connectedWalletInfo?.address)
      return "Connect wallet to move tower into wallet!";

    if (!linkedWalletInfo?.address)
      return "Link wallet to move tower into wallet!";
    if (
      !checkAddressIsSame(
        linkedWalletInfo?.address,
        connectedWalletInfo?.address
      )
    )
      return (
        <div className="">
          <p>Linked wallet not match current connected wallet!</p>
          <p>Link connected wallet to move tower to wallet!</p>
        </div>
      );
    return "";
  }, [linkedWalletInfo, connectedWalletInfo]);

  const handleClickBtn = async () => {
    if (disabledBtn) return;

    setIsMovingToWallet(true);
    try {
      await withdrawNFT(nftToken);

      toast.success("Move tower to wallet success!");

      setIsMovingToWallet(false);
      if (typeof moveNftToWalletSuccess === "function")
        moveNftToWalletSuccess();
    } catch (error) {
      toast.error("Move tower to wallet fail!");
      setIsMovingToWallet(false);
    }
  };

  return (
    <Tooltip arrow title={tooltipTitle}>
      <button
        type="button"
        className={`w-auto h-14 px-6 flex items-center justify-center gap-2 rounded-md ${
          disabledBtn ? "bg-orange-500" : "bg-[#00B800]"
        }`}
        onClick={handleClickBtn}
        disabled={disabledBtn}
      >
        {isMovingToWallet ? <CircularProgress size={24} /> : null}
        <span>Move into wallet</span>
      </button>
    </Tooltip>
  );
}

export default MoveNftToWallet;
