import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Backdrop, Dialog, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  authorizeUseToken,
  buyNFT,
  getTokenBalance,
} from "app/services/web3Services";

import gameTokenIcon from "app/assets/images/land.png";

const buyTowerStatus = {
  START: "START",
  AUTHORIZING: "AUTHORIZING",
  AUTHORIZATION_FAILED: "AUTHORIZATION_FAILED",
  AUTHORIZATION_SUCCESS: "AUTHORIZATION_SUCCESS",
  BUYING: "BUYING",
  BUYING_SUCCESS: "BUYING_SUCCESS",
  BUYING_FAILED: "BUYING_FAILED",
};

function CommonDialog(props) {
  const { open, handleClose, children, title, dialogWidth } = props;

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        classes: {
          root: "!text-white !relative h-[auto]",
        },
        style: {
          width: dialogWidth || "640px",
          maxWidth: dialogWidth || "640px",
        },
      }}
    >
      <div className="w-full h-full bg-gray-500">
        <div className="w-full h-[50px] px-12 flex items-center justify-center relative">
          <p
            className="text-[24px] leading-6 pt-1 text-[#8dff8d] font-medium uppercase"
            style={{
              textShadow: "0 0 5px #00B800",
            }}
          >
            {title}
          </p>

          <button
            type="button"
            className="w-7 h-7 cursor-pointer absolute top-1/2 right-5 -translate-y-1/2"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>

        <div className="w-full h-[calc(100%-50px)]">{children}</div>
      </div>
    </Dialog>
  );
}

function BuyStepByStep({ handleBuyTowerStatus }) {
  return (
    <div className="w-full h-auto flex items-center justify-center">
      <div className="w-8 h-8 flex items-center justify-center rounded-full overflow-hidden border-[3px] border-[#00B800]">
        {[
          buyTowerStatus.START,
          buyTowerStatus.AUTHORIZING,
          buyTowerStatus.AUTHORIZATION_FAILED,
        ].includes(handleBuyTowerStatus) ? (
          "1"
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-[#00B800] rounded-full">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
      </div>

      <div className="w-40 h-[3px] bg-[#00B800]" />

      <div className="w-8 h-8 flex items-center justify-center rounded-full overflow-hidden border-[3px] border-[#00B800]">
        2
      </div>
    </div>
  );
}

function NftPriceInfo(props) {
  const { nftInfo, yourBalance } = props;

  return (
    <>
      <div className="w-full h-9 flex items-center justify-between rounded border border-[#00B800] px-3 mt-8 mb-2">
        <img
          src={gameTokenIcon}
          alt=""
          className="w-5 h-5 rounded overflow-hidden"
        />

        <span>{nftInfo.price}</span>
      </div>

      <div className="w-full h-auto mb-8">Your Balance: {yourBalance}</div>
    </>
  );
}

function BuyTowerDialogContent(props) {
  const { nftInfo, handleBuySuccess } = props;
  const configs = useSelector((state) => state.appConfigs.configs);

  const [yourBalance, setYourBalance] = useState(0);
  const [handleBuyTowerStatus, setHandleBuyTowerStatus] = useState(
    buyTowerStatus.START
  );
  const [txHash, setTxHash] = useState("");

  useEffect(() => {
    getTokenBalance(nftInfo.currencyAddress).then((res) => setYourBalance(res));
  }, [nftInfo]);

  const showLoading = useMemo(
    () =>
      [buyTowerStatus.AUTHORIZING, buyTowerStatus.BUYING].includes(
        handleBuyTowerStatus
      ),
    [handleBuyTowerStatus]
  );

  const handleAuthorization = async () => {
    if (
      ![buyTowerStatus.START, buyTowerStatus.AUTHORIZATION_FAILED].includes(
        handleBuyTowerStatus
      )
    )
      return;

    const nftPrice = nftInfo.price;
    const tokenContractAddress = nftInfo.currencyAddress;

    setHandleBuyTowerStatus(buyTowerStatus.AUTHORIZING);
    authorizeUseToken(Number(nftPrice), tokenContractAddress)
      .then(() => {
        setHandleBuyTowerStatus(buyTowerStatus.AUTHORIZATION_SUCCESS);
      })
      .catch((err) => {
        setHandleBuyTowerStatus(buyTowerStatus.AUTHORIZATION_FAILED);
      });
  };

  const handleBuy = () => {
    if (
      ![
        buyTowerStatus.AUTHORIZATION_SUCCESS,
        buyTowerStatus.BUYING_FAILED,
      ].includes(handleBuyTowerStatus)
    )
      return;

    const nftId = nftInfo.nftId;
    const nftPrice = nftInfo.price;
    const tokenContractAddress = nftInfo.currencyAddress;

    setHandleBuyTowerStatus(buyTowerStatus.BUYING);
    buyNFT(nftId, nftPrice, tokenContractAddress)
      .then((transactionResult) => {
        setHandleBuyTowerStatus(buyTowerStatus.BUYING_SUCCESS);
        setTxHash(transactionResult.hash);
        if (handleBuySuccess) handleBuySuccess();
      })
      .catch((err) => {
        setHandleBuyTowerStatus(buyTowerStatus.BUYING_FAILED);
      });
  };

  const redirectToShowTransaction = () => {
    const scanWebUrl = configs?.BSCSCAN_URL || "https://testnet.bscscan.com";

    window.open(`${scanWebUrl}/tx/${txHash}`, "_blank");
  };

  const redirectToMyAccess = () => {
    const myAssetsUrl =
      configs?.REACT_APP_GAME_PLAY_URL || "https://racing.game99.xyz";

    window.open(`${myAssetsUrl}/my-assets`, "_blank");
  };

  const handleClickBtn = () => {
    if (
      [buyTowerStatus.AUTHORIZING, buyTowerStatus.BUYING].includes(
        handleBuyTowerStatus
      )
    )
      return;

    if (
      [buyTowerStatus.START, buyTowerStatus.AUTHORIZATION_FAILED].includes(
        handleBuyTowerStatus
      )
    )
      handleAuthorization();
    else if (
      [
        buyTowerStatus.AUTHORIZATION_SUCCESS,
        buyTowerStatus.BUYING_FAILED,
      ].includes(handleBuyTowerStatus)
    )
      handleBuy();
  };

  const getBuyBtnContent = () => {
    switch (handleBuyTowerStatus) {
      case buyTowerStatus.START:
      case buyTowerStatus.AUTHORIZATION_FAILED:
        return <p>APPROVE</p>;
      case buyTowerStatus.AUTHORIZATION_SUCCESS:
      case buyTowerStatus.BUYING_FAILED:
        return <p>BUY</p>;
      case buyTowerStatus.AUTHORIZING:
        return (
          <>
            <CircularProgress size={20} />
            <p className="ml-3">APPROVE</p>
          </>
        );
      case buyTowerStatus.BUYING:
        return (
          <>
            <CircularProgress size={20} />
            <p className="ml-3">BUY</p>
          </>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="w-full h-full p-8 flex flex-col items-center justify-center bg-gray-700">
        {handleBuyTowerStatus !== buyTowerStatus.BUYING_SUCCESS ? (
          <div className="w-full h-auto">
            <BuyStepByStep handleBuyTowerStatus={handleBuyTowerStatus} />

            <NftPriceInfo nftInfo={nftInfo} yourBalance={yourBalance} />

            <div className="w-full h-auto flex items-center justify-center">
              <button
                type="button"
                className="w-40 h-9 rounded-md flex items-center justify-center bg-[#00B800]"
                onClick={handleClickBtn}
              >
                {getBuyBtnContent()}
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center flex-col y-4">
            <h2 className="text-2xl uppercase">COMPLETE</h2>

            <FontAwesomeIcon icon={faCircleCheck} />

            <div className="w-full h-auto flex items-center justify-center">
              <div className="w-1/2 h-8 px-4">
                <button
                  type="button"
                  onClick={redirectToShowTransaction}
                  className="w-full h-full flex items-center justify-center text-sm"
                >
                  View transaction
                </button>
              </div>
              <div className="w-1/2 h-8 px-4">
                <button
                  type="button"
                  onClick={redirectToMyAccess}
                  className="w-full h-full flex items-center justify-center text-sm"
                >
                  Go to My Assets
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <Backdrop open={showLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}

function BuyTowerDialog(props) {
  const { nftInfo, handleClose, handleBuySuccess } = props;

  return (
    <CommonDialog
      open={nftInfo}
      handleClose={handleClose}
      title="BUY NOW"
      dialogWidth="460px"
    >
      {nftInfo ? (
        <BuyTowerDialogContent
          nftInfo={nftInfo}
          handleBuySuccess={handleBuySuccess}
        />
      ) : null}
    </CommonDialog>
  );
}

export default BuyTowerDialog;
