import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  authorizeHdWallet,
  depositNFTIntoGame,
} from "../../../services/web3Services";
import { toast } from "react-toastify";
import { CircularProgress, Tooltip } from "@mui/material";
import { checkAddressIsSame } from "../../../utils";
import { getInGameInventory } from "../../../services/api";

function MoveNftToGame(props) {
  const { nftToken, moveNftToGameSuccess, towerInfo } = props;
  const user = useSelector((state) => state.auth.user);
  const hasLoggedIn = useSelector((state) => state.auth.token?.accessToken);
  const linkedWalletInfo = useSelector((state) => state.auth.linkedWalletInfo);
  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );

  const [loadInGameTowerState, setLoadInGameTowerState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const [isMovingToGame, setIsMovingToGame] = useState(false);

  const loadInGameInventory = useCallback(() => {
    setLoadInGameTowerState((oldState) => ({
      ...oldState,
      isLoading: true,
      error: null,
    }));

    getInGameInventory(1)
      .then((res) => {
        setLoadInGameTowerState({
          isLoading: false,
          data: Array.isArray(res.data.rows) ? res.data.rows : [],
          error: null,
        });
      })
      .catch((error) => {
        setLoadInGameTowerState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInGameInventory();
  }, [loadInGameInventory]);

  const disabledBtn = useMemo(() => {
    return (
      !user ||
      !hasLoggedIn ||
      !linkedWalletInfo?.address ||
      !connectedWalletInfo?.address ||
      !checkAddressIsSame(
        linkedWalletInfo?.address,
        connectedWalletInfo?.address
      ) ||
      loadInGameTowerState.isLoading ||
      loadInGameTowerState.error ||
      !Array.isArray(loadInGameTowerState.data) ||
      loadInGameTowerState.data.find(
        (item) => item.is_machine_type === towerInfo.is_machine_type
      )
    );
  }, [
    user,
    hasLoggedIn,
    linkedWalletInfo?.address,
    connectedWalletInfo?.address,
    loadInGameTowerState.isLoading,
    loadInGameTowerState.error,
    loadInGameTowerState.data,
    towerInfo.is_machine_type,
  ]);

  const tooltipTitle = useMemo(() => {
    if (!user || !hasLoggedIn) return "Login to move tower into game!";
    if (!linkedWalletInfo?.address)
      return "Link wallet to move tower into game!";

    if (loadInGameTowerState.isLoading)
      return (
        <div className="flex items-center gap-2">
          <CircularProgress size={24} />
          <p>Checking town type</p>
        </div>
      );

    if (loadInGameTowerState.error)
      return (
        <div className="flex items-center gap-2">
          <p>Town type check failed!</p>
        </div>
      );

    if (
      Array.isArray(loadInGameTowerState.data) &&
      loadInGameTowerState.data.find(
        (item) => item.is_machine_type === towerInfo.is_machine_type
      )
    )
      return (
        <div className="flex items-center gap-2">
          <p>You already have 1 of this type of tower in the game!</p>
        </div>
      );

    if (
      !checkAddressIsSame(
        linkedWalletInfo?.address,
        connectedWalletInfo?.address
      )
    )
      return (
        <div className="">
          <p>Linked wallet not match current connected wallet!</p>
          <p>Link connected wallet to move tower into game!</p>
        </div>
      );
    return "";
  }, [
    user,
    hasLoggedIn,
    linkedWalletInfo?.address,
    loadInGameTowerState.isLoading,
    loadInGameTowerState.error,
    loadInGameTowerState.data,
    connectedWalletInfo?.address,
    towerInfo.is_machine_type,
  ]);

  const handleClickBtn = async () => {
    if (disabledBtn) return;

    setIsMovingToGame(true);
    try {
      await authorizeHdWallet(nftToken);
      await new Promise((resolve, reject) => {
        setTimeout(() => resolve(true), 500);
      });
      await depositNFTIntoGame(nftToken);

      toast.success("Move tower to game success!");
      setIsMovingToGame(false);
      if (typeof moveNftToGameSuccess === "function") moveNftToGameSuccess();
    } catch (error) {
      toast.error("Move tower to game fail!");
      setIsMovingToGame(false);
    }
  };

  return (
    <Tooltip arrow title={tooltipTitle}>
      <button
        type="button"
        className={`w-auto h-14 px-6 flex items-center justify-center gap-2 rounded-md ${
          disabledBtn ? "bg-orange-500" : "bg-[#00B800]"
        }`}
        onClick={handleClickBtn}
        disabled={disabledBtn}
      >
        {isMovingToGame ? <CircularProgress size={24} /> : null}
        <span>Move into game</span>
      </button>
    </Tooltip>
  );
}

export default MoveNftToGame;
