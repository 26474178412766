import { useEffect, useState } from "react";
import { mkpFilterToken } from "../../../utils/mkpFilterParams";
import selectDropdownIcon from "app/assets/svg/select_dropdown_icon.svg";
import { Popover } from "@mui/material";

export default function SelectTokenFilter(props) {
  const { selectedKey, handleSelectToken } = props;
  const [showOptionsEl, setShowOptionsEl] = useState(null);
  const [selectWidth, setSelectWidth] = useState("auto");

  useEffect(() => {
    if (showOptionsEl) {
      setSelectWidth(`${showOptionsEl.offsetWidth}px`);
    }
  }, [showOptionsEl]);

  const handleClick = (newKey) => {
    handleSelectToken(newKey);
  };

  const getSelectedContent = () => {
    const selected = mkpFilterToken.optionList.find(
      (option) => option.key === selectedKey || (!option.key && !selectedKey)
    );

    if (!selected || !selected.key)
      return (
        <span className="text-[22px] leading-[30px] font-semibold text-white">
          Select Currency
        </span>
      );
    return (
      <div className="flex items-center">
        <img alt="" className="w-5 h-5 mr-3" src={selected.icon} />
        <span className="text-[22px] leading-[30px] font-semibold text-white">
          {selected.label}
        </span>
      </div>
    );
  };

  return (
    <>
      <button
        onClick={(e) => setShowOptionsEl(e.currentTarget)}
        className="w-full h-[56px] px-8 py-3 xl:h-[72px] xl:px-12 xl:py-5 flex items-center justify-between border-2 border-solid border-[#00B800] rounded-[20px] mb-8"
      >
        {getSelectedContent()}

        <img alt="" className="w-5 h-5" src={selectDropdownIcon} />
      </button>

      <Popover
        open={Boolean(showOptionsEl)}
        anchorEl={showOptionsEl}
        onClose={() => setShowOptionsEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          classes: {
            root: "!h-auto !bg-[#2a2a2a] !rounded !p-2 !translate-y-2 border-2 border-solid border-[#00B800] !rounded-[20px]",
          },
          style: {
            width: selectWidth,
          },
        }}
      >
        <div className="w-full h-full flex flex-col text-white text-sm ">
          {mkpFilterToken.optionList.map((option) => (
            <button
              type="button"
              key={option.key}
              className={`w-full h-[56px] px-8 py-3 xl:h-[72px] xl:px-12 xl:py-5 flex items-center justify-start rounded-[20px] ${
                option.key === selectedKey
                  ? "bg-[#5b4ecf]"
                  : "bg-transparent hover:bg-[#51558a]"
              } `}
              onClick={() => {
                setShowOptionsEl(null);
                if (option.key !== selectedKey) handleClick(option.key);
              }}
            >
              {option.key === "" ? (
                <span className="text-[18px] leading-[24px] xl:text-[20px] xl:leading-[28px] font-semibold text-white">
                  {option.label}
                </span>
              ) : (
                <>
                  <img alt="" className="w-5 h-5 mr-3" src={option.icon} />
                  <span className="text-[18px] leading-[24px] xl:text-[20px] xl:leading-[28px] font-semibold text-white">
                    {option.label}
                  </span>
                </>
              )}
            </button>
          ))}
        </div>
      </Popover>
    </>
  );
}
