import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Dialog, Popover, Zoom } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faWallet,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import { linkWallet, unlinkCurrentWallet } from "app/services/api";
import { setLinkedWalletInfo } from "app/redux/authReducer";
import LoadingBackdrop from "../CommonUI/LoadingBackdrop";

export function ConfirmLinkWallet(props) {
  const { open, handleClose, handleLinkWallet } = props;

  const userEmail = useSelector((state) => state.auth.user?.email);
  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: "backdrop-blur-sm ",
      }}
      PaperProps={{
        style: {
          backgroundColor: "#68686888",
          minWidth: "360px",
          maxWidth: "460px",
          borderRadius: "12px",
          position: "relative",
          marginLeft: "16px",
          marginRight: "16px",
        },
      }}
      TransitionComponent={Zoom}
    >
      <button
        type="button"
        onClick={handleClose}
        className="absolute top-4 right-4 w-7 h-7 rounded-full flex items-center justify-center border-2 border-solid border-gray-200 text-gray-200 hover:border-white hover:text-white"
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>

      {/* children */}
      <div className="w-full h-auto">
        <div
          className="w-full h-14 pl-6 pr-14 flex items-center"
          style={{
            background:
              "linear-gradient(100.92deg, #00B800 13.57%, #FF6250 97.65%)",
          }}
        >
          <h2 className="text-xl font-semibold text-white">
            Link your wallet !
          </h2>
        </div>

        <div className="w-full h-auto p-6 bg-black">
          <h3 className="text-base font-medium text-gray-300">
            Link your wallet address to your game account to continue.
          </h3>
          <p className="text-green-500 text-sm my-4">
            <span className="text-[13.5px]">
              {connectedWalletInfo?.address}
            </span>{" "}
            will link to your account with email {userEmail}. Are you sure ?
          </p>

          <button
            type="button"
            onClick={handleLinkWallet}
            className="w-full h-12 flex items-center justify-center rounded bg-green-main hover:bg-opacity-90"
          >
            <p className="text-white">Link to {userEmail}</p>
          </button>
        </div>
      </div>
    </Dialog>
  );
}

function LoggedInMenu(props) {
  const { open, anchorEl, handleClose, handleLogout } = props;

  const [isCallingApi, setIsCallingApi] = useState(false);
  const [confirmLinkWallet, setConfirmLinkWallet] = useState(false);

  const dispatch = useDispatch();
  const linkedWalletInfo = useSelector((state) => state.auth.linkedWalletInfo);
  const user = useSelector((state) => state.auth.user);
  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );

  const handleLinkWallet = () => {
    if (!connectedWalletInfo || !connectedWalletInfo.address) {
      toast.error(
        <div className="flex flex-col">
          <p className="font-bold mb-1">Error</p>
          <p>Please connect your wallet first!</p>
        </div>
      );
      return;
    }

    setIsCallingApi(true);
    linkWallet(connectedWalletInfo.address)
      .then((res) => {
        dispatch(setLinkedWalletInfo({ address: connectedWalletInfo.address }));
        setConfirmLinkWallet(false);
        toast.success(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Success</p>
            <p>Link wallet successful!</p>
          </div>
        );
      })
      .catch((error) => {
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Error</p>
            <p>Link wallet failed!</p>
          </div>
        );
      })
      .finally(() => setIsCallingApi(false));
  };

  const handleUnlinkWallet = () => {
    setIsCallingApi(true);
    unlinkCurrentWallet(connectedWalletInfo)
      .then((res) => {
        dispatch(setLinkedWalletInfo(null));
        handleClose();
        toast.success(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Success</p>
            <p>Unlink wallet successful !</p>
          </div>
        );
      })
      .catch((error) => {
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Error</p>
            <p>Unlink wallet failed!</p>
          </div>
        );
      })
      .finally(() => setIsCallingApi(false));
  };

  const handleClickLinkWallet = () => {
    if (linkedWalletInfo) {
      handleUnlinkWallet();
    } else {
      if (!connectedWalletInfo) {
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Error</p>
            <p>Please connect your wallet first!</p>
          </div>
        );
        return;
      }

      setConfirmLinkWallet(true);
      handleClose();
    }
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: "12px",
              border: "1px solid white",
              padding: "12px 12px",
              backgroundColor: "#2b2b2b",
            },
          },
        }}
      >
        <div className="w-56 h-full">
          <button
            type="button"
            onClick={handleClickLinkWallet}
            className="w-full h-10 px-8 rounded-md flex items-center justify-start text-gray-200 hover:text-white hover:bg-[#222]"
          >
            <span className="text-lg">
              {linkedWalletInfo ? "Unlink Wallet" : "Link Wallet"}
            </span>
            <div className="w-8 h-8 flex items-center justify-center ml-2">
              <FontAwesomeIcon icon={faWallet} size="lg" />
            </div>
          </button>
        </div>
        <div className="w-56 h-full">
          <button
            type="button"
            onClick={handleLogout}
            className="w-full h-10 px-8 rounded-md flex items-center justify-start text-gray-200 hover:text-white hover:bg-[#222]"
          >
            <span className="text-lg">Logout</span>
            <div className="w-8 h-8 flex items-center justify-center ml-2">
              <FontAwesomeIcon icon={faArrowRightFromBracket} size="lg" />
            </div>
          </button>
        </div>
      </Popover>

      <LoadingBackdrop open={isCallingApi} />

      <ConfirmLinkWallet
        open={Boolean(confirmLinkWallet && connectedWalletInfo && user)}
        handleClose={() => setConfirmLinkWallet(false)}
        handleLinkWallet={handleLinkWallet}
      />
    </>
  );
}

export default LoggedInMenu;
