import { Pagination } from "@mui/material";
import React from "react";

function CommonPagination(props) {
  const { totalPages, ...rest } = props;
  return (
    <Pagination
      count={totalPages}
      hidePrevButton
      hideNextButton
      sx={{
        "& .MuiPagination-ul": {
          border: "1px solid #3B3B3B",
          borderRadius: "999px",
        },
        "& .MuiPaginationItem-root": {
          width: "46px",
          height: "46px",
          borderRadius: "999px",
          fontSize: "16px",
          fontFamily:
            'Space Mono, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          color: "#858584",
          margin: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiPaginationItem-root.Mui-selected": {
          color: "white",
        },
      }}
      {...rest}
    />
  );
}

export default CommonPagination;
