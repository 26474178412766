import React, { useLayoutEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

import Header from "./Header";

import "react-toastify/dist/ReactToastify.css";

function DefaultLayout(props) {
  const { headerProps, children } = props;
  const location = useLocation();

  useLayoutEffect(() => {
    // eslint-disable-next-line no-undef
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <>
      <div className="w-full h-auto min-h-screen bg-[#2B2B2B]">
        <Header {...headerProps} />
        <div className="w-full h-auto">{children}</div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default DefaultLayout;
