import React from "react";

function LoadingFail(props) {
  const { errorHandling, errorHandlingTitle } = props;
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h2 className="mb-8"> Error </h2>

      <button
        type="button"
        className=""
        onClick={() => {
          if (typeof errorHandling === "function") errorHandling();
        }}
      >
        {errorHandlingTitle || "Reload"}
      </button>
    </div>
  );
}

export default LoadingFail;
