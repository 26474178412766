import React, { useCallback, useEffect, useState } from "react";
import logo from "app/assets/images/logo.png";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import Path from "app/route/Path";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetPassword, verifyToken } from "../../services/api";
import InputPasswordField from "../../components/AuthDialog/InputPasswordField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function ResetPassword() {
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [resetPasswordStatus, setResetPasswordStatus] = useState({
    isLoading: false,
    isSuccess: false,
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleResetPassword = useCallback(() => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      toast.error(
        <div className="flex flex-col">
          <p className="font-bold mb-1">
            Password confirmation doesn't match the password
          </p>
        </div>
      );
      return;
    }

    setResetPasswordStatus({
      isLoading: true,
      isSuccess: false,
    });

    resetPassword(token, passwords.newPassword)
      .then((res) => {
        if (res.data.status === 200) {
          setResetPasswordStatus({
            isLoading: false,
            isSuccess: true,
          });
        } else {
          setResetPasswordStatus({
            isLoading: false,
            isSuccess: false,
          });
          toast.error(
            <div className="flex flex-col">
              <p className="font-bold mb-1">{res.data.message}</p>
            </div>
          );
        }
      })
      .catch(() => {
        setResetPasswordStatus({
          isLoading: false,
          isSuccess: false,
        });
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Reset password fail!</p>
          </div>
        );
      });
  }, [passwords.confirmPassword, passwords.newPassword, token]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          handleResetPassword();
        }}
        className="w-full h-auto flex flex-col items-center justify-center py-4"
      >
        <div className="w-20 h-auto mb-3">
          <img alt="logo" className="w-full h-auto" src={logo} />
        </div>
        <h1 className="text-[32px] leading-[38px] font-semibold text-white mb-4">
          Reset Password
        </h1>

        {resetPasswordStatus.isSuccess ? (
          <div className="w-full h-auto flex flex-col items-center justify-center py-12">
            <p className="text-xl font-bold text-white">
              Password reset successful!
            </p>

            <Link
              to={Path.home}
              className="px-6 py-2 rounded-lg bg-slate-300 mt-5"
            >
              <span className="text-xl font-semibold text-black">
                Back To Home
              </span>
            </Link>
          </div>
        ) : (
          <>
            <div className="w-full h-auto mb-4">
              <div className="w-full h-auto relative">
                <InputPasswordField
                  value={passwords.password}
                  placeholder="Enter New Password"
                  onChange={(e) => {
                    setPasswords((oldState) => ({
                      ...oldState,
                      newPassword: e.target.value,
                    }));
                  }}
                  required
                />
              </div>
            </div>
            <div className="w-full h-auto mb-4">
              <div className="w-full h-auto relative">
                <InputPasswordField
                  value={passwords.password}
                  placeholder="Enter Confirm Password"
                  onChange={(e) => {
                    setPasswords((oldState) => ({
                      ...oldState,
                      confirmPassword: e.target.value,
                    }));
                  }}
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-full h-[46px] flex items-center justify-center text-base leading-[22px] text-white rounded-full bg-purple-600 hover:bg-purple-500"
            >
              <span className="text-base font-semibold text-white">
                Reset Password
              </span>
            </button>
          </>
        )}
      </form>

      {resetPasswordStatus.isLoading ? (
        <Backdrop open className="flex items-center justify-center">
          <CircularProgress />
        </Backdrop>
      ) : null}
    </>
  );
}

function ResetPasswordWrapper() {
  const [tokenStatus, setTokenStatus] = useState("checking");

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleCheckToken = useCallback(() => {
    if (!token) {
      setTokenStatus("invalid");
      toast.error(
        <div className="flex flex-col">
          <p className="font-bold mb-1">Invalid token!</p>
        </div>
      );
      return;
    }

    setTokenStatus("checking");

    verifyToken(token)
      .then((res) => {
        if (res.data.status === 200) {
          setTokenStatus("valid");
        } else {
          setTokenStatus("invalid");
          toast.error(
            <div className="flex flex-col">
              <p className="font-bold mb-1">{res.data.message}</p>
            </div>
          );
        }
      })
      .catch(() => {
        setTokenStatus("invalid");
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Invalid token!</p>
          </div>
        );
      });
  }, [token]);

  useEffect(() => {
    handleCheckToken();
  }, [handleCheckToken]);

  return (
    <>
      <div className="w-full h-full min-h-screen flex items-center justify-center bg-[#222222]">
        <div className="w-full h-auto max-w-sm p-6 rounded-xl bg-[#2b2b2b] flex flex-col items-center justify-center">
          {tokenStatus === "checking" ? (
            <div className="flex flex-col items-center justify-center py-12 ">
              <CircularProgress />
            </div>
          ) : null}
          {tokenStatus === "invalid" ? (
              <div className="w-full h-auto py-12 px-4 flex flex-col items-center justify-center">
                <div className="w-20 h-20 flex items-center justify-center text-orange-500 mb-3">
                  <FontAwesomeIcon icon={faTriangleExclamation} size="3x" />
                </div>

                <h3 className="w-full text-xl font-bold text-center text-white mb-6">
                  Token invalid!
                </h3>

                <Link
                  to={Path.home}
                  className="w-full h-10 flex items-center justify-center rounded-full transition-all bg-[#888888] hover:bg-gray-400"
                >
                  <span className="text-base font-semibold text-white">
                    Back to Login
                  </span>
                </Link>
              </div>
     
          ) : null}

          {tokenStatus === "valid" ? <ResetPassword /> : null}
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default ResetPasswordWrapper;
