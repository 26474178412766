import { useEffect, useState } from "react";
import { mkpSortBy } from "../../../utils/mkpFilterParams";
import rocketIcon from "app/assets/svg/rocket.svg";
import { Popover } from "@mui/material";

export default function SelectOrderBy(props) {
  const { selectedKey, handleSelectSortBy } = props;
  const [showOptionsEl, setShowOptionsEl] = useState(null);
  const [selectWidth, setSelectWidth] = useState("auto");

  // useEffect(() => {
  //   if (showOptionsEl) {
  //     setSelectWidth(`${showOptionsEl.offsetWidth}px`);
  //   }
  // }, [showOptionsEl]);

  const handleClick = (newKey) => {
    handleSelectSortBy(newKey);
  };

  const getSelectedContent = () => {
    const selected = mkpSortBy.optionList.find(
      (option) => option.key === selectedKey || (!option.key && !selectedKey)
    );

    if (!selected || !selected.key)
      return (
        <>
          <span className="text-[18px] leading-[24px] xl:text-[20px] xl:leading-[28px] font-semibold text-white mr-3">
            {mkpSortBy.optionList[0].label}
          </span>
          <img alt="" className="w-5 h-5" src={rocketIcon} />
        </>
      );

    return (
      <>
        <span className="text-[18px] leading-[24px] xl:text-[20px] xl:leading-[28px] font-semibold text-white mr-3">
          {selected.label}
        </span>
        <img alt="" className="w-5 h-5" src={rocketIcon} />
      </>
    );
  };

  return (
    <>
      <button
        type="button"
        onClick={(e) => setShowOptionsEl(e.currentTarget)}
        className="!w-full xl:!w-auto lg:w-auto h-[56px] px-8 py-3 xl:h-[72px] xl:px-12 xl:py-5 font-semibold leading-8 bg-green-main rounded-[20px] flex items-center flex-nowrap"
      >
        {getSelectedContent()}
      </button>

      <Popover
        open={Boolean(showOptionsEl)}
        anchorEl={showOptionsEl}
        onClose={() => setShowOptionsEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          classes: {
            root: "!h-auto !bg-[#2a2a2a] !rounded !p-2 !translate-y-2 border-2 border-solid border-[#00B800] !rounded-[20px]",
          },
          style: {
            width: selectWidth,
          },
        }}
      >
        <div className="w-full h-full flex flex-col text-white text-sm ">
          {mkpSortBy.optionList.map((option) => (
            <button
              type="button"
              key={option.key}
              className={`w-full h-[56px] px-8 py-3 xl:h-[72px] xl:px-12 xl:py-5 flex items-center justify-start rounded-[20px] ${
                option.key === selectedKey
                  ? "bg-[#5b4ecf]"
                  : "bg-transparent hover:bg-[#51558a]"
              } `}
              onClick={() => {
                setShowOptionsEl(null);
                if (option.key !== selectedKey) handleClick(option.key);
              }}
            >
              <span className="text-[18px] leading-[24px] xl:text-[20px] xl:leading-[28px] font-semibold text-white mr-3 whitespace-nowrap">
                {option.label}
              </span>
              <img alt="" className="w-5 h-5" src={rocketIcon} />
            </button>
          ))}
        </div>
      </Popover>
    </>
  );
}
