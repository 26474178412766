import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import DefaultLayout from "app/layouts/DefaultLayout";
import TowerCard from "app/components/Card/CardTower";
import CommonPagination from "app/components/CommonUI/CommonPagination";
import LoadingProgress from "app/components/CommonUI/LoadingProgress";
import LoadingFail from "app/components/CommonUI/LoadingFail";
import BuyTowerDialog from "app/components/Dialogs/BuyTowerDialog";
import NoData from "app/components/CommonUI/NoData";
import { getAllListingItems } from "app/services/api";

import {
  compareSearchParamsWithStatus,
  convertStateToSearchParamsObj,
  getValidSearchParams,
  mkpFilterToken,
  mkpSearchText,
  mkpSortBy,
  mkpTowerType,
} from "app/utils/mkpFilterParams";
import SelectOrderBy from "./FilterComponents/SelectOrderBy";
import SearchText from "./FilterComponents/SearchText";
import SelectTokenFilter from "./FilterComponents/SelectTokenFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faSquare } from "@fortawesome/free-regular-svg-icons";
import { Drawer } from "@mui/material";
import { faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";

function FilterTowerBox(props) {
  const { filterationConditions, updateFilterAndLazySearchParams } = props;

  const handleResetFilter = () => {
    updateFilterAndLazySearchParams({
      ...filterationConditions,
      [mkpFilterToken.key]: mkpFilterToken.optionList[0].key,
      [mkpTowerType.key]: mkpTowerType.optionList[0].key,
      [mkpSortBy.key]: mkpSortBy.optionList[0].key,
      [mkpSearchText.key]: "",
    });
  };

  return (
    <div className="w-full h-auto text-white">
      <div className="w-full h-auto flex items-center justify-between mb-2.5">
        <h2 className="text-[28px] leading-10 font-semibold">Filter</h2>

        <button
          type="button"
          onClick={handleResetFilter}
          className="text-base text-[#00B800] leading-[22px] font-normal py-2"
        >
          Reset
        </button>
      </div>

      <div className="w-full h-auto block xl:hidden text-white mb-4">
        <SearchText
          search={filterationConditions?.[mkpSearchText.key]}
          handleSearchTextChange={(newText) => {
            updateFilterAndLazySearchParams(
              { ...filterationConditions, [mkpSearchText.key]: newText },
              { noLazy: false, delay: 1000 }
            );
          }}
        />
      </div>

      <div className="w-full h-auto block xl:hidden text-white mb-4">
        <SelectOrderBy
          selectedKey={filterationConditions?.[mkpSortBy.key]}
          handleSelectSortBy={(newSortByKey) => {
            updateFilterAndLazySearchParams(
              { ...filterationConditions, [mkpSortBy.key]: newSortByKey },
              { noLazy: true }
            );
          }}
        />
      </div>

      <SelectTokenFilter
        selectedKey={filterationConditions?.[mkpFilterToken.key]}
        handleSelectToken={(newTokenKey) => {
          updateFilterAndLazySearchParams(
            { ...filterationConditions, [mkpFilterToken.key]: newTokenKey },
            { noLazy: true }
          );
        }}
      />

      <div className="w-full h-auto">
        <div className="w-full h-auto flex items-center mb-2.5">
          <h2 className="text-[28px] leading-10 font-semibold">Filter</h2>
        </div>

        {mkpTowerType.optionList.map((item) => (
          <button
            onClick={() => {
              updateFilterAndLazySearchParams(
                { ...filterationConditions, [mkpTowerType.key]: item.key },
                { noLazy: true }
              );
            }}
            className={`w-full h-[56px] px-8 py-3 xl:h-[72px] xl:px-12 xl:py-5 flex items-center border-2 border-solid rounded-[20px] mb-2.5 gap-3 ${
              filterationConditions?.[mkpTowerType.key] == item.key
                ? "border-[#00B800]"
                : "border-[#858584]"
            }`}
          >
            <FontAwesomeIcon
              icon={
                filterationConditions?.[mkpTowerType.key] == item.key
                  ? faSquareCheck
                  : faSquare
              }
              size="lg"
              className={
                filterationConditions?.[mkpTowerType.key] == item.key
                  ? "text-[#00B800]"
                  : "text-white"
              }
            />

            <span className="text-[18px] leading-[24px] xl:text-[20px] xl:leading-[28px] font-semibold text-white">
              {item.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}

function MarketplacePage() {
  const [filterationConditions, setFilterationConditions] = useState({
    page: 1,
    search: "",
    token: mkpFilterToken.optionList[0].key,
    sortBy: mkpSortBy.optionList[0].key,
    type: mkpTowerType.optionList[0].key,
  });

  const [loadDataState, setLoadDataState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [openBuyTower, setOpenBuyTower] = useState(null);
  const [showFilterMobile, setShowFilterMobile] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const previousSearchParams = useRef(null);
  const updateSearchParamsTimeout = useRef(null);

  const synchronizeDataFromSearchParamsToState = useCallback(() => {
    const searchParamsObj = Object.fromEntries(searchParams.entries());
    const searchParamsDiffStatus = compareSearchParamsWithStatus(
      searchParamsObj,
      filterationConditions
    );

    if (Object.keys(searchParamsDiffStatus).length > 0) {
      const newFilterationConditions = {
        ...filterationConditions,
        ...searchParamsDiffStatus,
      };
      setFilterationConditions(newFilterationConditions);
      return newFilterationConditions;
    }

    return filterationConditions;
  }, [filterationConditions, searchParams]);

  useEffect(() => {
    if (previousSearchParams.current !== searchParams) {
      const searchParamsObj = Object.fromEntries(searchParams.entries());
      const newSearchParamsObj = getValidSearchParams(searchParamsObj);
      if (newSearchParamsObj !== searchParamsObj) {
        setSearchParams(new URLSearchParams(newSearchParamsObj));
      } else {
        const params = synchronizeDataFromSearchParamsToState();

        setLoadDataState({
          isLoading: true,
          data: null,
          error: null,
        });

        getAllListingItems(params)
          .then((res) => {
            if (Number(res.data.totalPages) > 0)
              setTotalPages(res.data.totalPages);

            setLoadDataState({
              isLoading: false,
              data: res.data.rows,
              error: null,
            });
          })
          .catch((error) => {
            toast.error("Load data fail!");
            setLoadDataState({
              isLoading: false,
              data: null,
              error: error,
            });
          });
      }
    }
    previousSearchParams.current = searchParams;
  }, [searchParams, setSearchParams, synchronizeDataFromSearchParamsToState]);

  const currentPage = useMemo(() => {
    if (
      searchParams &&
      searchParams.get("page") &&
      !Number.isNaN(Number(searchParams.get("page"))) &&
      Number(searchParams.get("page")) > 0
    ) {
      return Number(searchParams.get("page"));
    }

    return 1;
  }, [searchParams]);

  const handlePageChange = (e, value) => {
    const newFilter = {
      ...filterationConditions,
      page: value,
    };
    updateFilterAndLazySearchParams(newFilter, { noLazy: true });
  };

  const updateSearchParams = (filterState) => {
    const newSearchParamsObj = convertStateToSearchParamsObj(filterState);
    setSearchParams(new URLSearchParams(newSearchParamsObj));
  };

  const updateFilterAndLazySearchParams = (
    newFilter,
    options = { noLazy: false, delay: 500 }
  ) => {
    setFilterationConditions(newFilter);

    if (updateSearchParamsTimeout.current)
      clearTimeout(updateSearchParamsTimeout.current);

    updateSearchParamsTimeout.current = setTimeout(
      () => updateSearchParams(newFilter),
      options.noLazy ? 0 : options.delay
    );
  };

  return (
    <DefaultLayout>
      <div className="w-full h-auto py-[60px] px-0 bg-[#2B2B2B] text-white">
        <div className="custom-container-medium">
          <div className="w-full h-auto flex items-center justify-between mb-[34px] flex-wrap">
            <h1 className="text-[30px] leading-[40px] md:text-[40px] md:leading-[46px] lg:text-[44px] lg:leading-[50px] font-semibold">
              NFT Marketplace
            </h1>

            <div className="w-auto hidden xl:flex items-center ">
              {/* <button className="px-12 py-5 text-[22px] font-semibold leading-8 border-2 border-solid border-green-main rounded-[20px] mr-6 flex items-center">
                <span className="mr-3">Search Tower</span>
                <img alt="" className="w-5 h-5" src={searchTowerIcon} />
              </button> */}

              <SearchText
                search={filterationConditions?.[mkpSearchText.key]}
                handleSearchTextChange={(newText) => {
                  updateFilterAndLazySearchParams(
                    { ...filterationConditions, [mkpSearchText.key]: newText },
                    { noLazy: false, delay: 1000 }
                  );
                }}
              />

              <SelectOrderBy
                selectedKey={filterationConditions?.[mkpSortBy.key]}
                handleSelectSortBy={(newSortByKey) => {
                  updateFilterAndLazySearchParams(
                    { ...filterationConditions, [mkpSortBy.key]: newSortByKey },
                    { noLazy: true }
                  );
                }}
              />
            </div>

            <button
              className="w-10 h-10 bg-[#00B800] rounded-lg block xl:hidden flex items-center justify-center"
              type="button"
              onClick={() => setShowFilterMobile(true)}
            >
              <FontAwesomeIcon icon={faFilter} size="xl" />
            </button>
          </div>

          <div className="w-full h-auto flex">
            <div className="w-[305px] h-full hidden xl:block">
              <FilterTowerBox
                filterationConditions={filterationConditions}
                updateFilterAndLazySearchParams={
                  updateFilterAndLazySearchParams
                }
              />
            </div>

            <div className="w-full xl:w-[calc(100%-305px)] xl:pl-14">
              {loadDataState.isLoading ? <LoadingProgress /> : null}

              {!loadDataState.isLoading && loadDataState.error ? (
                <LoadingFail
                  error={loadDataState.error}
                  errorHandling={() => {}}
                />
              ) : null}

              {!loadDataState.isLoading &&
              !loadDataState.error &&
              Array.isArray(loadDataState.data) ? (
                loadDataState.data.length > 0 ? (
                  <div className="w-full h-auto flex justify-center flex-wrap gap-[30px] mb-[30px]">
                    {loadDataState.data.map((item) => (
                      <TowerCard
                        key={item._id}
                        towerInfo={item.tower}
                        price={item.price}
                      />
                    ))}
                  </div>
                ) : (
                  <NoData />
                )
              ) : null}

              {totalPages > 0 ? (
                <div className="w-full h-auto flex item-center justify-center">
                  <CommonPagination
                    page={currentPage}
                    onChange={handlePageChange}
                    totalPages={totalPages}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        anchor="left"
        open={showFilterMobile}
        onClose={() => setShowFilterMobile(false)}
        slotProps={{
          backdrop: {
            classes: {
              root: " bg-opacity-40",
            },
          },
        }}
      >
        <div className="w-[320px] h-auto p-4 bg-[#2b2b2b]">
          <div className="w-full h-auto mb-5 flex justify-end">
            <button
              type="button"
              onClick={() => setShowFilterMobile(false)}
              className="w-10 h-10 flex items-center justify-center text-white"
            >
              <FontAwesomeIcon icon={faXmark} size="2x" />
            </button>
          </div>
          <FilterTowerBox
            filterationConditions={filterationConditions}
            updateFilterAndLazySearchParams={updateFilterAndLazySearchParams}
          />
        </div>
      </Drawer>

      <BuyTowerDialog
        nftInfo={openBuyTower}
        handleClose={() => setOpenBuyTower(null)}
        handleBuySuccess={() => {}}
      />
    </DefaultLayout>
  );
}

export default MarketplacePage;
