import React, { useState } from "react";
import userLogin from "app/assets/svg/user_login.svg";
import AuthDialog from "../../../components/AuthDialog";
import { useDispatch, useSelector } from "react-redux";
import LoggedInMenu from "../../../components/LoggedInMenu";
import { setAuthLogout, setShowAuthDialog } from "../../../redux/authReducer";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import avatar from "app/assets/images/userAvatars/avatar_1.png";

function HeaderLoginBtn() {
  const [showLoggedInEl, setShowLoggedInEl] = useState(null);
  const dispatch = useDispatch();
  const hasLoggedIn = useSelector((state) => state.auth.token?.accessToken);
  const user = useSelector((state) => state.auth.user);
  const showAuthDialog = useSelector((state) => state.auth.showAuthDialog);

  const handleClickLogin = (e) => {
    if (hasLoggedIn) {
      setShowLoggedInEl(e.currentTarget);
      dispatch(setShowAuthDialog(false));
    } else {
      setShowLoggedInEl(null);
      dispatch(setShowAuthDialog(true));
    }
  };

  const handleLogout = () => {
    dispatch(setAuthLogout());
    toast.success(
      <div className="flex flex-col">
        <p className="font-bold mb-1">Logout Success</p>
      </div>
    );
  };

  const handleCloseAuthDialog = () => {
    dispatch(setShowAuthDialog(false));
  };

  return (
    <>
      {user && hasLoggedIn ? (
        <>
          <button
            type="button"
            onClick={handleClickLogin}
            className="md:hidden w-auto h-9 rounded-full pl-12 pr-3 relative bg-slate-500"
          >
            <div className="w-12 h-12 absolute left-0 -top-1.5 rounded-full bg-slate-500">
              <img alt="" className="w-full h-full object-cover" src={avatar} />
            </div>
            <div className="w-fulf h-full flex items-center justify-center pl-1">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </button>

          <button
            type="button"
            onClick={handleClickLogin}
            className="hidden md:flex w-auto h-14 px-8 2xl:h-[72px] 2xl:px-12 rounded-[20px] bg-green-main items-center justify-center text-white"
          >
            <img alt="" src={userLogin} className="w-5 h-5 mr-3" />
            <span className="text-[22px] leading-[30px] font-semibold">
              {user.email}
            </span>
          </button>
        </>
      ) : (
        <>
          {/* smaller button */}
          <button
            type="button"
            onClick={handleClickLogin}
            className="md:hidden w-auto h-9 rounded-md bg-gray-500 px-2 border-2 border-solid border-green-main"
          >
            <span className="text-sm leading-4 font-semibold">Log In</span>
          </button>

          {/* lager button */}
          <button
            type="button"
            onClick={handleClickLogin}
            className="hidden md:flex w-auto h-14 px-8 2xl:h-[72px] 2xl:px-12 rounded-[20px] bg-green-main items-center justify-center text-white"
          >
            <img alt="" src={userLogin} className="w-5 h-5 mr-3" />
            <span className="text-[22px] leading-[30px] font-semibold">
              Log In
            </span>
          </button>
        </>
      )}

      <AuthDialog
        open={!hasLoggedIn && showAuthDialog}
        handleClose={handleCloseAuthDialog}
      />

      <LoggedInMenu
        open={Boolean(!!showLoggedInEl && hasLoggedIn)}
        anchorEl={showLoggedInEl}
        handleClose={() => setShowLoggedInEl(null)}
        handleLogout={handleLogout}
      />
    </>
  );
}

export default HeaderLoginBtn;
