import { useEffect, useState } from "react";
import logo from "app/assets/images/logo.png";
import CommonAuthDialog from "./CommonAuthDialog";
import { callApiStatus } from "../../constants";
import { faCircleCheck, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { sendMailResetPassword } from "../../services/api";
import LoadingBackdrop from "../CommonUI/LoadingBackdrop";

function ForgotPasswordSendMail(props) {
  const { open, handleCloseForgotPassword } = props;

  const [sendRequestStatus, setSendRequestStatus] = useState(
    callApiStatus.idle
  );
  const [email, setEmail] = useState("");

  const resetState = () => {
    setEmail("");
    setTimeout(() => setSendRequestStatus(callApiStatus.idle), 300);
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const handleSendMailResetPassword = () => {
    if (sendRequestStatus === callApiStatus.loading) return "";

    setSendRequestStatus(callApiStatus.loading);

    sendMailResetPassword(email)
      .then((res) => {
        if (res.data.status === 200) {
          setSendRequestStatus(callApiStatus.success);
          toast.success(
            <div className="flex flex-col">
              <p className="font-bold mb-1">
                Send request reset password success
              </p>
              <p>Please check your mail to confirm reset password</p>
            </div>
          );
        } else {
          setSendRequestStatus(callApiStatus.error);
          toast.error(
            <div className="flex flex-col">
              <p className="font-bold mb-1">{res.data.msg}</p>
            </div>
          );
        }
      })
      .catch(() => {
        setSendRequestStatus(callApiStatus.error);
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Send request reset password fail!</p>
          </div>
        );
      });
  };

  return (
    <>
      <CommonAuthDialog open={open} handleClose={handleCloseForgotPassword}>
        {sendRequestStatus === callApiStatus.success ? (
          <div className="w-full h-auto py-6 px-4 flex flex-col items-center justify-center">
            <div className="w-20 h-20 flex items-center justify-center text-green-500 mb-3">
              <FontAwesomeIcon icon={faCircleCheck} size="3x" />
            </div>

            <h3 className="w-full text-xl font-bold text-center text-white mb-6">
              Please check your mail to confirm reset password
            </h3>

            <button
              type="button"
              onClick={() => handleCloseForgotPassword()}
              className="w-full h-10 flex items-center justify-center rounded-full transition-all bg-[#888888] hover:bg-gray-400"
            >
              <span className="text-base font-semibold text-white">
                Back to Login
              </span>
            </button>
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSendMailResetPassword();
            }}
            className="w-full h-auto flex flex-col items-center justify-center py-4"
          >
            <div className="w-20 h-auto mb-5">
              <img alt="logo" className="w-full h-auto" src={logo} />
            </div>
            <h2 className="text-xl font-bold text-white mb-4">
              Forget your password
            </h2>

            <div className="w-full h-auto mb-8">
              <div className="w-full h-auto">
                <div className="w-full h-auto relative">
                  <span className="absolute left-2 top-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-gray-500"
                    />
                  </span>
                  <input
                    type="email"
                    placeholder="Enter E-mail"
                    value={email}
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="w-full h-[46px] rounded-full pl-10 pr-5 py-3 text-base leading-[22px] bg-white text-gray-900"
                  />
                </div>
              </div>
            </div>

            <div className="w-full h-auto flex items-center">
              <div className="w-1/2 pr-4">
                <button
                  type="button"
                  onClick={handleCloseForgotPassword}
                  className="w-full h-10 flex items-center justify-center rounded-full transition-all bg-[#888888] hover:bg-gray-400"
                >
                  <span className="text-base font-semibold text-white">
                    Back
                  </span>
                </button>
              </div>

              <div className="w-1/2 pl-4">
                <button
                  type="submit"
                  className="w-full h-10 flex items-center justify-center rounded-full transition-all bg-purple-600 hover:bg-purple-500"
                >
                  <span className="text-base font-semibold text-white">
                    Confirm
                  </span>
                </button>
              </div>
            </div>
          </form>
        )}
      </CommonAuthDialog>

      <LoadingBackdrop open={sendRequestStatus === callApiStatus.loading} />
    </>
  );
}

export default ForgotPasswordSendMail;
