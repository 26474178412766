import React from "react";
import noItem from "app/assets/images/empty-box.png";

function NoData() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center py-12">
      <img alt="" className="w-36 h-36 mb-6" src={noItem} />
      <h3 className="text-2xl font-semibold text-[#858584]">No Item</h3>
    </div>
  );
}

export default NoData;
