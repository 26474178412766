import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function InputPasswordField(props) {
  const { value, onChange, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="w-full h-auto relative">
      <span className="absolute left-2 top-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center">
        <FontAwesomeIcon icon={faLock} className="text-gray-500" />
      </span>
      <input
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        className="w-full h-[46px] rounded-full px-10 py-3 text-base leading-[22px] bg-white text-gray-900"
        {...rest}
      />

      <button
        type="button"
        onClick={() => setShowPassword((oldState) => !oldState)}
        className="absolute right-2 top-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center"
      >
        <FontAwesomeIcon
          icon={showPassword ? faEye : faEyeSlash}
          size="lg"
          color={showPassword ? "black" : "gray"}
        />
      </button>
    </div>
  );
}

export default InputPasswordField;
