import React, { useState } from "react";
import { cancelListNft } from "../../../services/web3Services";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

function CancelListNft(props) {
  const { nftToken, cancelListNftSuccess } = props;
  const [isCancelListing, setIsCancelListing] = useState(false);

  const handleCancelListing = () => {
    if (isCancelListing) return;

    setIsCancelListing(true);

    cancelListNft(nftToken)
      .then(() => {
        setIsCancelListing(false);
        toast.success("Unlist tower success!");
        if (typeof cancelListNftSuccess === "function") cancelListNftSuccess();
      })
      .catch(() => {
        toast.error("Unlist tower fail!");
        setIsCancelListing(false);
      });
  };

  return (
    <div className="w-full h-auto">
      <button
        type="button"
        className="w-auto h-14 px-6 flex items-center justify-center gap-2 rounded-md bg-[#00B800]"
        onClick={handleCancelListing}
        disabled={false}
      >
        {isCancelListing ? <CircularProgress size={24} /> : null}
        <span>Cancel Listing</span>
      </button>
    </div>
  );
}

export default CancelListNft;
