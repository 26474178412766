import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultLayout from "app/layouts/DefaultLayout";
import emptyFolder from "app/assets/images/empty-folder.png";
import { setShowAuthDialog } from "app/redux/authReducer";

import LinkWalletButton from "./LinkWalletButton";
import WithdrawForm from "./WithdrawForm";
import WithdrawHistory from "./WithdrawHistory";

function WithdrawPage() {
  const [reloadHistory, setReloadHistory] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const linkedWalletInfo = useSelector((state) => state.auth.linkedWalletInfo);
  const hasLoggedIn = useSelector((state) => state.auth.token?.accessToken);

  const handleShowAuthDialog = () => {
    dispatch(setShowAuthDialog(true));
  };

  return (
    <DefaultLayout>
      <div className="w-full h-auto py-[60px] px-0 bg-[#2B2B2B] text-white">
        {user && hasLoggedIn ? (
          <>
            <div className="custom-container-small">
              <h1 className="text-[32px] leading-[40px] md:text-[40px] md:leading-[46px] lg:text-[44px] lg:leading-[50px] font-semibold mb-12">
                Withdraw
              </h1>

              <div className="w-full h-auto">
                {!linkedWalletInfo ? (
                  <div className="w-full h-auto py-16 flex flex-col items-center justify-center">
                    <h3 className="text-xl lg:text-2xl font-semibold text-center mb-6">
                      please link wallet to Withdraw
                    </h3>

                    <LinkWalletButton />
                  </div>
                ) : (
                  <WithdrawForm
                    handleWithdrawSuccess={() => {
                      setReloadHistory((oldState) => !oldState);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="custom-container-medium">
              <div className="w-full h-auto">
                <WithdrawHistory reload={reloadHistory} />
              </div>
            </div>
          </>
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="w-24 h-24 mb-6">
              <img
                src={emptyFolder}
                alt="required connect wallet"
                className="w-full h-full"
              />
            </div>

            <p className="text-base font-semibold text-gray-300 mb-6">
              Please connect your wallet to see the data
            </p>

            <button
              type="button"
              onClick={handleShowAuthDialog}
              className="w-auto h-9 px-2 rounded-full flex items-center justify-center text-white bg-green-main ml-3"
            >
              <span className="text-sm leading-4 font-semibold">
                Connect Your Wallet
              </span>
            </button>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
}

export default WithdrawPage;
