import USDT_ICON from "app/assets/svg/coin_logo/USDT.svg";
import LAND_ICON from "app/assets/svg/coin_logo/LAND.svg";

export const mkpPage = {
  key: "page",
};

export const mkpSearchText = {
  key: "search",
};

export const mkpSortBy = {
  key: "sortBy",
  type: "",
  optionList: [
    {
      key: "",
      label: "Newest",
    },
    {
      key: "price_desc",
      label: "Highest Price",
    },
    {
      key: "price_esc",
      label: "Lowest Price",
    },
  ],
};

export const mkpTowerType = {
  key: "type",
  type: "",
  optionList: [
    {
      key: "",
      label: "All",
    },
    {
      key: "1",
      label: "Machine Tower",
    },
    {
      key: "2",
      label: "Slow Tower",
    },
    {
      key: "3",
      label: "Laser Tower",
    },
    {
      key: "4",
      label: "Voltage Tower",
    },
    {
      key: "5",
      label: "Reaper Tower",
    },
    {
      key: "6",
      label: "Toxic Tower",
    },
    {
      key: "7",
      label: "Canon Tower",
    },
    {
      key: "8",
      label: "Tober Tower",
    },
    {
      key: "9",
      label: "Tesla Tower",
    },
    {
      key: "10",
      label: "Mission Tower",
    },
  ],
};

export const mkpFilterToken = {
  key: "token",
  type: "",
  optionList: [
    {
      key: "",
      label: "All",
      icon: null,
    },
    {
      key: "usdt",
      label: "USDT",
      icon: USDT_ICON,
    },
    {
      key: "land",
      label: "SLAND",
      icon: LAND_ICON,
    },
  ],
};

export const getValidSearchParams = (searchParamsObj) => {
  let hasChanged = false;
  const newFilterChanges = {};

  if (
    searchParamsObj[mkpFilterToken.key] !== undefined &&
    !mkpFilterToken.optionList.find(
      (item) => item.key === searchParamsObj[mkpFilterToken.key]
    )
  ) {
    hasChanged = true;
    newFilterChanges[mkpFilterToken.key] = mkpFilterToken.optionList[0].key;
  }

  if (
    searchParamsObj[mkpSortBy.key] !== undefined &&
    !mkpSortBy.optionList.find(
      (item) => item.key === searchParamsObj[mkpSortBy.key]
    )
  ) {
    hasChanged = true;
    newFilterChanges[mkpSortBy.key] = mkpSortBy.optionList[0].key;
  }

  if (
    searchParamsObj[mkpTowerType.key] !== undefined &&
    !mkpTowerType.optionList.find(
      (item) => item.key === searchParamsObj[mkpTowerType.key]
    )
  ) {
    hasChanged = true;
    newFilterChanges[mkpTowerType.key] = mkpTowerType.optionList[0].key;
  }

  if (
    searchParamsObj[mkpPage.key] !== undefined &&
    (Number.isNaN(Number(searchParamsObj[mkpPage.key])) ||
      Number(searchParamsObj[mkpPage.key]) <= 0)
  ) {
    hasChanged = true;
    newFilterChanges[mkpPage.key] = "";
  }

  const newFilter = {
    ...searchParamsObj,
    ...newFilterChanges,
  };

  // Remove value is empty string
  Object.keys(newFilter).forEach((key) => {
    if (newFilter[key] === "") {
      hasChanged = true;
      delete newFilter[key];
    }
  });

  return hasChanged ? newFilter : searchParamsObj;
};

export const compareSearchParamsWithStatus = (
  searchParamsObj,
  filterationConditions
) => {
  const newFilterChanges = {};

  // check page
  if (
    filterationConditions[mkpPage.key] === 1 &&
    searchParamsObj[mkpPage.key] &&
    Number(searchParamsObj[mkpPage.key]) !== 1
  ) {
    newFilterChanges[mkpPage.key] = 1;
  } else if (
    filterationConditions[mkpPage.key] !== 1 &&
    Number(searchParamsObj[mkpPage.key]) !== filterationConditions[mkpPage.key]
  ) {
    newFilterChanges[mkpPage.key] = filterationConditions[mkpPage.key];
  }

  // check search text
  if (
    searchParamsObj[mkpSearchText.key] !==
      filterationConditions[mkpSearchText.key]
  ) {
    newFilterChanges[mkpSearchText.key] =
      searchParamsObj[mkpSearchText.key] || mkpSearchText.defaultValue;
  }

  // check sort by
  if (searchParamsObj[mkpSortBy.key] !== filterationConditions[mkpSortBy.key]) {
    if (searchParamsObj[mkpSortBy.key]) {
      newFilterChanges[mkpSortBy.key] = searchParamsObj[mkpSortBy.key];
    } else {
      newFilterChanges[mkpSortBy.key] = mkpSortBy.optionList[0].key;
    }
  }

  // check token
  if (
    searchParamsObj[mkpFilterToken.key] !==
    filterationConditions[mkpFilterToken.key]
  ) {
    if (searchParamsObj[mkpFilterToken.key]) {
      newFilterChanges[mkpFilterToken.key] =
        searchParamsObj[mkpFilterToken.key];
    } else {
      newFilterChanges[mkpFilterToken.key] = mkpFilterToken.optionList[0].key;
    }
  }

  // check type
  if (
    searchParamsObj[mkpTowerType.key] !==
    filterationConditions[mkpTowerType.key]
  ) {
    if (searchParamsObj[mkpTowerType.key]) {
      newFilterChanges[mkpTowerType.key] = searchParamsObj[mkpTowerType.key];
    } else {
      newFilterChanges[mkpTowerType.key] = mkpTowerType.optionList[0].key;
    }
  }

  return newFilterChanges;
};

export const convertStateToSearchParamsObj = (filterState) => {
  const searchParamsObj = {};

  // page
  if (filterState[mkpPage.key] !== 1)
    searchParamsObj[mkpPage.key] = filterState[mkpPage.key];

  // search text
  if (filterState[mkpSearchText.key])
    searchParamsObj[mkpSearchText.key] = filterState[mkpSearchText.key];

  // sort by
  if (
    filterState[mkpSortBy.key] &&
    filterState[mkpSortBy.key] !== mkpSortBy.optionList[0].key
  )
    searchParamsObj[mkpSortBy.key] = filterState[mkpSortBy.key];

  // currency
  if (
    filterState[mkpFilterToken.key] &&
    filterState[mkpFilterToken.key] !== mkpFilterToken.optionList[0].key
  )
    searchParamsObj[mkpFilterToken.key] = filterState[mkpFilterToken.key];

  // type
  if (
    filterState[mkpTowerType.key] &&
    filterState[mkpTowerType.key] !== mkpTowerType.optionList[0].key
  )
    searchParamsObj[mkpTowerType.key] = filterState[mkpTowerType.key];

  return searchParamsObj;
};
