import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog } from "@mui/material";
import { NumericFormat } from "react-number-format";
import withdrawSuccessIcon from "app/assets/svg/withdraw_success_icon.svg";
import rocketIcon from "app/assets/svg/rocket.svg";
import LoadingProgress from "../../../components/CommonUI/LoadingProgress";
import LoadingFail from "../../../components/CommonUI/LoadingFail";
import { toast } from "react-toastify";
import { loadWithdrawSettings, postWithdrawBit } from "../../../services/api";
import LoadingBackdrop from "../../../components/CommonUI/LoadingBackdrop";
import { useSelector } from "react-redux";

const callApiStatus = {
  idle: "idle",
  loading: "loading",
  error: "error",
  success: "success",
};

function WithdrawalSuccessfulDialog(props) {
  const { open, handleClose } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "20px",
          backgroundColor: "#3B3B3B",
        },
      }}
    >
      <div className="p-8 flex flex-col items-center justify-center">
        <div className="w-40 h-40 mb-5">
          <img alt="" className="w-full h-full" src={withdrawSuccessIcon} />
        </div>
        <p className="text-[22px] leading-8 font-semibold text-white mb-5">
          Withdrawal Request Successful !
        </p>

        <button
          type="button"
          onClick={handleClose}
          className="w-auto h-[72px] flex items-center justify-center px-[50px] py-5 rounded-[20px] bg-[#52D376] text-white"
        >
          <span className="mr-3">Done</span>
          <img alt="" className="w-5 h-5" src={rocketIcon} />
        </button>
      </div>
    </Dialog>
  );
}

function WithdrawFormContent(props) {
  const {
    diamondToBitRate,
    minDiamond,
    handleWithdrawSuccess,
  } = props;

  const [numberOfDiamondToken, setNumberOfDiamondToken] = useState(0);
  const [callingWithdrawStatus, setCallingWithdrawStatus] = useState(
    callApiStatus.idle
  );

  const gameTokenSymbol = useSelector(
    (state) => state.appConfigs?.configs?.GAME_TOKEN_SYMBOL
  );

  const maxDiamondToWithdraw = 1_000_000_000;

  const numberOfBitToken = useMemo(() => {
    if (numberOfDiamondToken < minDiamond) return 0;

    return numberOfDiamondToken * diamondToBitRate;
  }, [numberOfDiamondToken, minDiamond, diamondToBitRate]);

  const handleWithdraw = () => {
    if (
      numberOfDiamondToken < minDiamond ||
      numberOfDiamondToken > maxDiamondToWithdraw
    )
      return;

    setCallingWithdrawStatus(callApiStatus.loading);
    postWithdrawBit(numberOfDiamondToken)
      .then((res) => {
        setCallingWithdrawStatus(callApiStatus.success);
        toast.success("Withdrawal success.");

        // handle success and reset form
        typeof handleWithdrawSuccess === "function" && handleWithdrawSuccess();
        setNumberOfDiamondToken(0);
      })
      .catch((error) => {
        setCallingWithdrawStatus(callApiStatus.error);
        toast.error("Withdrawal failed, please try again!");
      });
  };

  return (
    <>
      <div className="w-full h-auto mb-[50px]">
        <div className="w-full h-auto mb-[15px]">
          <label
            htmlFor="token_diamond"
            className="text-base leading-[22px] text-white font-normal block mb-[15px]"
          >
            Token Diamond
          </label>

          <NumericFormat
            value={numberOfDiamondToken}
            onValueChange={(values, sourceInfo) => {
              setNumberOfDiamondToken(values.floatValue);
            }}
            allowNegative={false}
            decimalScale={0}
            valueIsNumericString={true}
            thousandSeparator=","
            className="w-full h-[46px] rounded-full px-5 py-3 text-base leading-[22px] bg-white text-gray-900"
          />

          {numberOfDiamondToken !== 0 && numberOfDiamondToken < minDiamond ? (
            <p className="text-red-500">
              Minimum diamonds to convert is {minDiamond}
            </p>
          ) : null}

          {numberOfDiamondToken !== 0 &&
          numberOfDiamondToken > maxDiamondToWithdraw ? (
            <p className="text-red-500">
              The maximum amount of diamonds you can withdraw is:{" "}
              {new Intl.NumberFormat().format(maxDiamondToWithdraw)}
            </p>
          ) : null}
        </div>

        <div className="w-full h-auto mb-[30px]">
          <label
            htmlFor="token_bit"
            className="text-base leading-[22px] text-white font-normal block mb-[15px]"
          >
            Token {gameTokenSymbol || ""}
          </label>
          <NumericFormat
            value={numberOfBitToken}
            allowNegative={false}
            decimalScale={2}
            valueIsNumericString={true}
            thousandSeparator=","
            className="w-full h-[46px] rounded-full px-5 py-3 text-base leading-[22px] bg-white text-gray-900"
            disabled
          />
        </div>

        <div className="w-full h-auto mb-[30px]">
          <button
            type="button"
            onClick={handleWithdraw}
            className="w-full h-[46px] flex items-center justify-center text-base leading-[22px] text-white rounded-full bg-green-main"
          >
            Confirm Withdraw
          </button>
        </div>

        <div className="w-full h-auto">
          <p className="w-full text-base leading-[22px] text-white">
            <span className="text-sm text-gray-400">Convert Rate: </span>
            <span className="">
              1 Diamond = {diamondToBitRate}{" "}
              <span className="capitalize">
                {typeof gameTokenSymbol === "string"
                  ? gameTokenSymbol.toLowerCase()
                  : ""}
              </span>
            </span>
          </p>
        </div>
      </div>

      <LoadingBackdrop open={callingWithdrawStatus === callApiStatus.loading} />

      <WithdrawalSuccessfulDialog
        open={callingWithdrawStatus === callApiStatus.success}
        handleClose={() => {
          setCallingWithdrawStatus(callApiStatus.idle);
        }}
      />
    </>
  );
}

function WithdrawForm(props) {
  const { handleWithdrawSuccess } = props;
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState((oldState) => ({
      ...oldState,
      isLoading: true,
      error: null,
    }));

    loadWithdrawSettings()
      .then((res) => {
        const diamondToBitRate = Number(res.data.diamondToBitRate) || 1;
        const minDiamond = Number(res.data.minDiamond) || 0;

        if (minDiamond !== undefined && diamondToBitRate !== undefined) {
          setLoadInitState({
            isLoading: false,
            data: { diamondToBitRate, minDiamond },
            error: null,
          });
        } else {
          // TODO
          toast.error("Load rate fail!");
          setLoadInitState({
            isLoading: false,
            data: null,
            error: true,
          });
        }
      })
      .catch((error) => {
        toast.error("Load data fail!");
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  if (loadInitState.isLoading)
    return (
      <div className="w-full` h-auto mb-[50px]">
        <LoadingProgress />
      </div>
    );

  if (loadInitState.error) {
    return (
      <div className="w-full` h-auto mb-[50px]">
        <LoadingFail />
      </div>
    );
  }

  if (loadInitState.data)
    return (
      <WithdrawFormContent
        diamondToBitRate={loadInitState.data.diamondToBitRate}
        minDiamond={loadInitState.data.minDiamond}
        handleWithdrawSuccess={handleWithdrawSuccess}
      />
    );

  // TODO
  return null;
}

export default WithdrawForm;
