import { useEffect, useState } from "react";
import logo from "app/assets/images/logo.png";
import CommonAuthDialog from "./CommonAuthDialog";
import RegisterWithEmailDialog from "./RegisterWithEmailDialog";
import InputPasswordField from "./InputPasswordField";
import ForgotPasswordSendMail from "./ForgotPasswordSendMail";
import LoadingBackdrop from "../CommonUI/LoadingBackdrop";
import jwtDecode from "jwt-decode";
import { callApiStatus } from "../../constants";
import { login } from "../../services/api";
import { toast } from "react-toastify";
import { setAuthToken, setAuthUser } from "../../redux/authReducer";
import { useDispatch } from "react-redux";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AuthDialog(props) {
  const { open, handleClose } = props;

  const [showRegister, setShowRegister] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [loginStatus, setLoginStatus] = useState(callApiStatus.idle);

  const dispatch = useDispatch();

  const resetState = () => {
    setShowRegister(false);
    setShowForgotPassword(false);
    setLoginStatus(callApiStatus.idle);
    setLoginData({
      email: "",
      password: "",
    });
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const handleLogin = () => {
    if (loginStatus === callApiStatus.loading) return "";

    setLoginStatus(callApiStatus.loading);

    login(loginData.email, loginData.password)
      .then((res) => {
        const user = jwtDecode(res.data.token);

        dispatch(
          setAuthUser({
            email: user.email,
            id: user.id,
          })
        );
        dispatch(
          setAuthToken({
            accessToken: res.data.token,
          })
        );
        setLoginStatus(callApiStatus.success);
        toast.success(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Login Success</p>
          </div>
        );
      })
      .catch(() => {
        setLoginStatus(callApiStatus.error);
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Login Fail</p>
            <p>Login name or password is incorrect</p>
          </div>
        );
      });
  };

  return (
    <>
      <CommonAuthDialog open={open} handleClose={handleClose}>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            handleLogin();
          }}
          className="w-full h-auto flex flex-col items-center justify-center py-4"
        >
          <div className="w-20 h-auto mb-3">
            <img alt="logo" className="w-full h-auto" src={logo} />
          </div>
          <h1 className="text-[38px] leading-[38px] font-semibold text-white mb-4">
            Login
          </h1>
          <p className="text-base text-gray-300 text-center mb-5">
            <span>New user? </span>
            <button
              type="button"
              onClick={() => setShowRegister(true)}
              className="text-yellow-500"
            >
              Register now
            </button>
          </p>

          <div className="w-full h-auto mb-4">
            <div className="w-full h-auto relative">
              <span className="absolute left-2 top-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center">
                <FontAwesomeIcon icon={faEnvelope} className="text-gray-500" />
              </span>
              <input
                type="email"
                placeholder="Enter E-mail"
                value={loginData.email}
                onChange={(e) => {
                  setLoginData((oldState) => ({
                    ...oldState,
                    email: e.target.value,
                  }));
                }}
                required
                className="w-full h-[46px] rounded-full pl-10 pr-5 py-3 text-base leading-[22px] bg-white text-gray-900"
              />
            </div>
          </div>

          <div className="w-full h-auto mb-4">
            <div className="w-full h-auto relative">
              <InputPasswordField
                value={loginData.password}
                placeholder="Enter Password"
                onChange={(e) => {
                  setLoginData((oldState) => ({
                    ...oldState,
                    password: e.target.value,
                  }));
                }}
                required
              />
            </div>
          </div>

          <div className="w-full h-auto mb-3">
            <button
              type="button"
              onClick={() => setShowForgotPassword(true)}
              className="text-yellow-500"
            >
              Forgot password?
            </button>
          </div>

          <button
            type="submit"
            className="w-full h-[46px] flex items-center justify-center text-base leading-[22px] text-white rounded-full bg-purple-600 hover:bg-purple-500"
          >
            <span className="text-base font-semibold text-white">Login</span>
          </button>
        </form>
      </CommonAuthDialog>
      <RegisterWithEmailDialog
        open={showRegister}
        handleCloseRegister={() => setShowRegister(false)}
        handleCloseAuthDialog={handleClose}
      />
      <ForgotPasswordSendMail
        open={showForgotPassword}
        handleCloseForgotPassword={() => setShowForgotPassword(false)}
      />

      <LoadingBackdrop open={loginStatus === callApiStatus.loading} />
    </>
  );
}

export default AuthDialog;
