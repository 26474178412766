import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  connectWallet,
  getListingNftInfo,
  getUserNftInfo,
  getNftOwnerInfo,
} from "app/services/web3Services";
import { CircularProgress } from "@mui/material";
import BuyNft from "./BuyNft";
import CancelListNft from "./CancelListNft";
import { ethers } from "ethers";
import ListNft from "./ListNft";
import MoveNftToGame from "./MoveNftToGame";
import MoveNftToWallet from "./MoveNftToWallet";
import { checkAddressIsSame } from "../../../utils";

function TowerOnMarket(props) {
  const { nftToken, reloadOwnerInfo } = props;
  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );
  const gameTokenSymbol = useSelector(
    (state) => state.appConfigs?.configs?.GAME_TOKEN_SYMBOL
  );

  const [loadListingInfoState, setLoadListingInfoState] = useState({
    isLoading: true,
    data: {
      seller: "",
      price: "",
    },
  });

  const getListingInfo = useCallback(() => {
    setLoadListingInfoState({
      isLoading: true,
      data: {
        seller: "",
        price: "",
        currency: "",
      },
    });

    getListingNftInfo(nftToken)
      .then((res) => {
        setLoadListingInfoState({
          isLoading: false,
          data: {
            seller: res.seller.toLowerCase(),
            price: ethers.formatUnits(res.price, 18),
            currency: res.currency,
          },
        });
      })
      .catch((err) => {
        toast.error("Load listing nft info fail!");
        setLoadListingInfoState({
          isLoading: false,
          data: {
            seller: "",
            price: "",
            currency: "",
          },
        });
      });
  }, [nftToken]);

  useEffect(() => {
    getListingInfo();
  }, [getListingInfo]);

  if (loadListingInfoState.isLoading)
    return (
      <div className="w-full h-auto py-4 flex items-center justify-center">
        <CircularProgress size={32} />
      </div>
    );

  return (
    <div className="w-full h-auto">
      <p className="font-space_mono text-[22px] text-[#00B800] font-bold leading-9 mb-2">
        Current Price
      </p>
      <p className="font-space_mono text-[22px] font-bold leading-9 mb-5">
        {loadListingInfoState.data?.price} {gameTokenSymbol}
      </p>

      {!connectedWalletInfo?.address ? (
        <button
          type="button"
          className="w-auto h-14 px-6 flex items-center justify-center gap-2 rounded-md bg-[#00B800]"
          onClick={connectWallet}
        >
          <span>Connect wallet to buy</span>
        </button>
      ) : checkAddressIsSame(
          loadListingInfoState.data?.seller,
          connectedWalletInfo?.address
        ) ? (
        <CancelListNft
          nftToken={nftToken}
          cancelListNftSuccess={reloadOwnerInfo}
        />
      ) : (
        <BuyNft
          nftToken={nftToken}
          price={loadListingInfoState.data?.price}
          currency={loadListingInfoState.data?.currency}
          handleBuySuccess={reloadOwnerInfo}
        />
      )}
    </div>
  );
}

function TowerInGame(props) {
  const { nftToken, reloadOwnerInfo } = props;

  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );

  const [loadOwnerUserState, setLoadOwnerUserState] = useState({
    isLoading: true,
    userNft: null,
  });

  const getCurrentOwnerUser = useCallback(() => {
    setLoadOwnerUserState({
      isLoading: true,
      userNft: null,
    });

    getUserNftInfo(nftToken)
      .then((userNft) => {
        setLoadOwnerUserState({
          isLoading: false,
          userNft: {
            userAddress: userNft?.userAddress,
            status: userNft?.status,
          },
        });
      })
      .catch((err) => {
        toast.error("Load user nft fail!");
        setLoadOwnerUserState({
          isLoading: false,
          ownerAddress: null,
        });
      });
  }, [nftToken]);

  useEffect(() => {
    getCurrentOwnerUser();
  }, [getCurrentOwnerUser]);

  if (loadOwnerUserState.isLoading)
    return (
      <div className="w-full h-auto py-4 flex items-center justify-center">
        <CircularProgress size={32} />
      </div>
    );

  if (
    connectedWalletInfo?.address &&
    checkAddressIsSame(
      loadOwnerUserState?.userNft?.userAddress,
      connectedWalletInfo?.address
    )
  )
    return (
      <MoveNftToWallet
        nftToken={nftToken}
        moveNftToWalletSuccess={reloadOwnerInfo}
      />
    );

  return null;
}

function TowerInWallet(props) {
  const { nftToken, ownerAddress, reloadOwnerInfo, towerInfo } = props;

  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );

  if (
    connectedWalletInfo?.address &&
    checkAddressIsSame(connectedWalletInfo?.address, ownerAddress)
  )
    return (
      <div className="w-full h-auto flex items-center gap-6 my-5">
        <ListNft nftToken={nftToken} handleListTowerSuccess={reloadOwnerInfo} />

        <MoveNftToGame
          nftToken={nftToken}
          moveNftToGameSuccess={reloadOwnerInfo}
          towerInfo={towerInfo}
        />
      </div>
    );

  return <div></div>;
}

function TowerNftStatusAndAction(props) {
  const { ownerAccountId, nftToken, towerInfo } = props;

  const towerHoldInGameAddress = useSelector(
    (state) => state.appConfigs?.configs?.TOWER_HOLD_IN_GAME_ADDRESS
  );
  const marketContractAddress = useSelector(
    (state) => state.appConfigs?.configs?.MARKET_CONTRACT_ADDRESS
  );

  const [loadOwnerState, setLoadOwnerState] = useState({
    isLoading: true,
    ownerAddress: "",
  });

  const getCurrentOwner = useCallback(() => {
    setLoadOwnerState({
      isLoading: true,
      ownerAddress: "",
    });

    getNftOwnerInfo(nftToken)
      .then((address) => {
        setLoadOwnerState({
          isLoading: false,
          ownerAddress: address?.toLowerCase() || "",
        });
      })
      .catch((err) => {
        toast.error("Load nft owner fail!");
        setLoadOwnerState({
          isLoading: false,
          ownerAddress: "",
        });
      });
  }, [nftToken]);

  useEffect(() => {
    getCurrentOwner();
  }, [getCurrentOwner]);

  if (loadOwnerState.isLoading)
    return (
      <div className="w-full h-auto py-4 flex items-center justify-center">
        <CircularProgress size={32} />
      </div>
    );

  if (
    loadOwnerState.ownerAddress &&
    checkAddressIsSame(loadOwnerState.ownerAddress, towerHoldInGameAddress)
  )
    return (
      <TowerInGame
        nftToken={nftToken}
        reloadOwnerInfo={getCurrentOwner}
      />
    );

  if (
    loadOwnerState.ownerAddress &&
    checkAddressIsSame(loadOwnerState.ownerAddress, marketContractAddress)
  )
    return (
      <TowerOnMarket nftToken={nftToken} reloadOwnerInfo={getCurrentOwner} />
    );

  if (loadOwnerState.ownerAddress)
    return (
      <TowerInWallet
        nftToken={nftToken}
        ownerAddress={loadOwnerState.ownerAddress}
        reloadOwnerInfo={getCurrentOwner}
        towerInfo={towerInfo}
      />
    );

  return null;
}

export default TowerNftStatusAndAction;
