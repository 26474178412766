import store from "app/redux/store";
import LANDTokenIcon from "app/assets/images/land.png";
import { ethers } from "ethers";

export const getSummaryAddress = (address, numberOfLetter = 4) =>
  `${address.slice(0, numberOfLetter)}...${address.slice(-numberOfLetter)}`;

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const checkAddressIsSame = (address1, address2) => {
  if (
    typeof address1 === "string" &&
    typeof address2 === "string" &&
    address1.toLowerCase() === address2.toLowerCase()
  )
    return true;

  return false;
};

export const getTokensUsedToList = () => {
  return [
    {
      tokenContractAddressSettingKey:
        store.getState()?.appConfigs?.configs?.GAME_TOKEN_CONTRACT_ADDRESS,
      label: store.getState()?.appConfigs?.configs?.GAME_TOKEN_SYMBOL,
      icon: LANDTokenIcon,
    },
  ];
};

export const paddedToChecksumAddress = (strAddr) => {
  if (strAddr.slice(0, 2) === "0x") strAddr = strAddr.slice(2);
  while (strAddr.slice(0, 2) === "00") strAddr = strAddr.slice(2);
  return ethers.getAddress(`0x${strAddr}`);
};
