const routePaths = {
  home: "/home",
  wallet: "/wallet",
  walletWithdraw: "/wallet/withdraw",
  marketplace: "/marketplace",
  inventory: "/inventory",
  forgot: "/forgot",
  verifyEmail: "/verifyEmail",

  towerDetail: (index) =>
    index !== undefined ? `/tower/${index}` : "/tower/:index",
};

export default routePaths;
