import React, { useCallback, useEffect, useState } from "react";
import {
  authorizeUseToken,
  buyNFT,
  getTokenBalance,
} from "../../../services/web3Services";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const buyStatusOptions = {
  BALANCE_CHECKING: "BALANCE_CHECKING",
  BALANCE_FAILED: "BALANCE_FAILED",
  BALANCE_SUCCESS: "BALANCE_SUCCESS",

  AUTHORIZING: "AUTHORIZING",
  AUTHORIZATION_SUCCESS: "AUTHORIZATION_SUCCESS",

  BUYING: "BUYING",
  BUYING_SUCCESS: "BUYING_SUCCESS",
};

function BuyNft(props) {
  const { nftToken, price, currency, handleBuySuccess } = props;

  const [buyTowerStatus, setBuyTowerStatus] = useState(
    buyStatusOptions.BALANCE_CHECKING
  );

  const checkBalance = useCallback(() => {
    setBuyTowerStatus(buyStatusOptions.BALANCE_CHECKING);
    getTokenBalance(currency)
      .then((res) => {
        if (true) {
          // TODO
          setBuyTowerStatus(buyStatusOptions.BALANCE_SUCCESS);
        } else {
          setBuyTowerStatus(buyStatusOptions.BALANCE_FAILED);
        }
      })
      .catch((error) => {
        setBuyTowerStatus(buyStatusOptions.BALANCE_FAILED);
      });
  }, [currency]);

  useEffect(() => {
    checkBalance();
  }, [checkBalance]);

  const handleAuthorization = () => {
    if (buyTowerStatus !== buyStatusOptions.BALANCE_SUCCESS) return;

    const nftPrice = price;
    const tokenContractAddress = currency;

    setBuyTowerStatus(buyStatusOptions.AUTHORIZING);
    authorizeUseToken(Number(nftPrice), tokenContractAddress)
      .then(() => {
        setBuyTowerStatus(buyStatusOptions.AUTHORIZATION_SUCCESS);
      })
      .catch((err) => {
        toast.error("Authorization fail!");
        setBuyTowerStatus(buyStatusOptions.BALANCE_SUCCESS);
      });
  };

  const handleBuy = () => {
    if (buyTowerStatus !== buyStatusOptions.AUTHORIZATION_SUCCESS) return;

    const nftId = nftToken;
    const nftPrice = price;
    const tokenContractAddress = currency;

    setBuyTowerStatus(buyStatusOptions.BUYING);
    buyNFT(nftId, nftPrice, tokenContractAddress)
      .then((transactionResult) => {
        setBuyTowerStatus(buyStatusOptions.BUYING_SUCCESS);
        if (handleBuySuccess) handleBuySuccess();
      })
      .catch((err) => {
        toast.error("Buy NFT fail!");
        setBuyTowerStatus(buyStatusOptions.AUTHORIZATION_SUCCESS);
      });
  };

  const handleClickBtn = () => {
    if (
      [
        buyStatusOptions.BALANCE_CHECKING,
        buyStatusOptions.BALANCE_FAILED,
        buyStatusOptions.AUTHORIZING,
        buyStatusOptions.BUYING,
      ].includes(buyTowerStatus)
    )
      return;

    if (buyTowerStatus === buyStatusOptions.BALANCE_SUCCESS)
      handleAuthorization();
    else if (buyTowerStatus === buyStatusOptions.AUTHORIZATION_SUCCESS)
      handleBuy();
  };

  const getBuyBtnContent = () => {
    switch (buyTowerStatus) {
      case buyStatusOptions.BALANCE_CHECKING:
        return (
          <div className="flex items-center gap-2">
            <CircularProgress size={24} />
            <span>Check balance</span>
          </div>
        );
      case buyStatusOptions.BALANCE_FAILED:
        return (
          <div className="flex items-center">
            <span>Your balance is not enough!</span>
          </div>
        );
      case buyStatusOptions.BALANCE_SUCCESS:
        return <p className="">Approve</p>;
      case buyStatusOptions.AUTHORIZING:
      case buyStatusOptions.BUYING:
        return (
          <div className="flex items-center gap-2">
            <CircularProgress size={24} />
            <span>Processing</span>
          </div>
        );
      case buyStatusOptions.AUTHORIZATION_SUCCESS:
        return <p className="">Purchase</p>;
      case buyStatusOptions.BUYING_SUCCESS:
        return <p className="">BUYING_SUCCESS</p>;
      default:
        return "";
    }
  };

  return (
    <div className="w-full h-auto">
      <button
        type="button"
        className="w-auto h-14 px-6 flex items-center justify-center gap-2 rounded-md bg-[#00B800]"
        onClick={handleClickBtn}
        disabled={false}
      >
        {getBuyBtnContent()}
      </button>
    </div>
  );
}

export default BuyNft;
