import React, { useState } from "react";
import SellTowerDialog from "app/components/Dialogs/SellTowerDialog";

function ListNft(props) {
  const { nftToken, handleListTowerSuccess } = props;

  const [towerToSell, setTowerToSell] = useState(null);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setTowerToSell({
            nft_token: nftToken,
          });
        }}
        className="w-auto h-14 px-6 flex items-center justify-center gap-2 rounded-md bg-[#00B800]"
      >
        List to market
      </button>

      <SellTowerDialog
        towerToSell={towerToSell}
        handleClose={() => setTowerToSell(null)}
        handleListSuccess={handleListTowerSuccess}
      />
    </>
  );
}

export default ListNft;
