import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  Popover,
  Zoom,
} from "@mui/material";

import { authorizeMarketToSellNFT, listNft } from "app/services/web3Services";
import { getTokensUsedToList } from "../../utils";

const listNFTStatusOptions = {
  INPUT_PRICE: "INPUT_PRICE",

  AUTHORIZING: "AUTHORIZING",
  AUTHORIZATION_SUCCESS: "AUTHORIZATION_SUCCESS",

  LISTING_TO_MARKETPLACE: "LISTING_TO_MARKETPLACE",
  LIST_TO_MARKETPLACE_SUCCESS: "LIST_TO_MARKETPLACE_SUCCESS",
  LIST_TO_MARKETPLACE_FAILED: "LIST_TO_MARKETPLACE_FAILED",
};

function CustomDialog(props) {
  const { children, open, handleClose, title } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: "backdrop-blur-sm ",
      }}
      PaperProps={{
        style: {
          backgroundColor: "#2b2b2b",
          minWidth: "360px",
          maxWidth: "460px",
          borderRadius: "12px",
          position: "relative",
          marginLeft: "16px",
          marginRight: "16px",
        },
      }}
      TransitionComponent={Zoom}
    >
      <button
        type="button"
        onClick={handleClose}
        className="absolute top-4 right-4 w-7 h-7 rounded-full flex items-center justify-center border-2 border-solid border-gray-200 text-gray-200 hover:border-white hover:text-white"
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>

      {/* children */}
      <div className="w-full h-auto min-w-[460px]">
        <div
          className="w-full h-14 pl-6 pr-14 flex items-center"
          style={{
            background:
              "linear-gradient(100.92deg, #00B800 13.57%, #FF6250 97.65%)",
          }}
        >
          <h2 className="text-xl font-semibold text-white">{title}</h2>
        </div>

        <div className="w-full h-auto p-6 bg-[#2b2b2b]">{children}</div>
      </div>
    </Dialog>
  );
}

function SelectTokenToList(props) {
  const { selected, handleSelect, disabled } = props;
  const [showOptionsEl, setShowOptionsEl] = useState(null);

  return (
    <>
      <button
        type="button"
        className="w-full h-[46px] rounded-full pl-2 pr-8 py-3 text-base leading-[22px] bg-white text-gray-900 relative"
        onClick={(e) => !disabled && setShowOptionsEl(e.currentTarget)}
      >
        <div className="w-full text-base flex items-center justify-center gap-2">
          <img src={selected?.icon} alt="" className="w-5 h-5" />
          <span className="font-semibold">{selected?.label}</span>
        </div>

        <div
          className={`absolute right-4 top-1/2 -translate-y-1/2 text-base leading-4 transition-all ${
            showOptionsEl ? "rotate-180" : ""
          }`}
        >
          <FontAwesomeIcon icon={faCaretDown} size="lg" />
        </div>
      </button>
      <Popover
        open={Boolean(showOptionsEl) && !disabled}
        anchorEl={showOptionsEl}
        onClose={() => setShowOptionsEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: "144px",
            borderRadius: "12px",
            transform: "translateY(8px)",
          },
        }}
      >
        <div className="w-full h-full flex flex-col text-base bg-white text-gray-900 p-2 gap-y-1">
          {getTokensUsedToList().map((option) => (
            <button
              type="button"
              key={option.key}
              className="w-full h-[46px] rounded-[12px] px-2 py-3 flex items-center gap-2 hover:bg-cyan-100"
              onClick={() => {
                setShowOptionsEl(null);

                if (option.key !== selected) handleSelect(option);
              }}
            >
              <img src={option.icon} alt="" className="w-5 h-5" />
              <span className=" text-base leading-[22px] font-semibold">
                {option.label}
              </span>
            </button>
          ))}
        </div>
      </Popover>
    </>
  );
}

function SellTowerDialogContent(props) {
  const { towerToSell, handleListSuccess } = props;
  const configs = useSelector((state) => state.appConfigs.configs);

  const [selectedTokenObj, setSelectedTokenObj] = useState(() => {
    const tokensUsedToList = getTokensUsedToList();
    return tokensUsedToList[0];
  });
  const [priceInput, setPriceInput] = useState("");

  const [requireInputError, setRequireInputError] = useState(false);
  const [listNftStatus, setListNftStatus] = useState(
    listNFTStatusOptions.INPUT_PRICE
  );
  const [txHash, setTxHash] = useState("");

  const showLoading = useMemo(
    () =>
      [
        listNFTStatusOptions.AUTHORIZING,
        listNFTStatusOptions.LISTING_TO_MARKETPLACE,
      ].includes(listNftStatus),
    [listNftStatus]
  );

  const handleChangePriceInput = (e) => {
    setPriceInput(e.currentTarget.value);
  };

  const handleAuthorization = async () => {
    if (listNftStatus !== listNFTStatusOptions.INPUT_PRICE) return;

    if (!priceInput) {
      setRequireInputError(true);
      return;
    }

    const numberOfTokenAuthorize = Number(priceInput);
    const tokenContractAddress = selectedTokenObj.tokenContractAddressSettingKey;

    if (Number.isNaN(numberOfTokenAuthorize) || !tokenContractAddress) {
      setListNftStatus(listNFTStatusOptions.INPUT_PRICE);
      return;
    }

    setListNftStatus(listNFTStatusOptions.AUTHORIZING);
    try {
      await authorizeMarketToSellNFT(towerToSell.nft_token);
      setListNftStatus(listNFTStatusOptions.AUTHORIZATION_SUCCESS);
    } catch (err) {
      setListNftStatus(listNFTStatusOptions.INPUT_PRICE);

      toast.error(err?.info?.error?.message);
    }
  };

  const handleListing = () => {
    if (listNftStatus !== listNFTStatusOptions.AUTHORIZATION_SUCCESS) return;

    const numberOfTokenAuthorize = Number(priceInput);
    const tokenContractAddress = selectedTokenObj.tokenContractAddressSettingKey;
    if (Number.isNaN(numberOfTokenAuthorize) || !tokenContractAddress) {
      setListNftStatus(listNFTStatusOptions.INPUT_PRICE);
      return;
    }

    setListNftStatus(listNFTStatusOptions.LISTING_TO_MARKETPLACE);
    listNft(towerToSell.nft_token, tokenContractAddress, numberOfTokenAuthorize)
      .then((transactionResult) => {
        setListNftStatus(listNFTStatusOptions.LIST_TO_MARKETPLACE_SUCCESS);
        setTxHash(transactionResult.hash);
        if (handleListSuccess) handleListSuccess();
      })
      .catch((error) => {
        setListNftStatus(listNFTStatusOptions.LIST_TO_MARKETPLACE_FAILED);

        toast.error(error?.info?.error?.message);
      });
  };

  const handleClickBtn = () => {
    if (
      [
        listNFTStatusOptions.AUTHORIZING,
        listNFTStatusOptions.LISTING_TO_MARKETPLACE,
        listNFTStatusOptions.LIST_TO_MARKETPLACE_SUCCESS,
      ].includes(listNftStatus)
    )
      return;

    if (listNftStatus === listNFTStatusOptions.INPUT_PRICE)
      handleAuthorization();
    else if (listNftStatus === listNFTStatusOptions.AUTHORIZATION_SUCCESS)
      handleListing();
  };

  const getBuyBtnContent = () => {
    switch (listNftStatus) {
      case listNFTStatusOptions.INPUT_PRICE:
        return <span>Approve</span>;
      case listNFTStatusOptions.AUTHORIZING:
      case listNFTStatusOptions.LISTING_TO_MARKETPLACE:
        return (
          <div className="flex items-center gap-2">
            <CircularProgress size={24} />
            <span>Processing</span>
          </div>
        );
      case listNFTStatusOptions.AUTHORIZATION_SUCCESS:
        return <p className="">List to market</p>;
      case listNFTStatusOptions.LIST_TO_MARKETPLACE_SUCCESS:
        return <p className="">View transaction</p>;
      default:
        return "";
    }
  };

  const redirectToShowTransaction = () => {
    const scanWebUrl = configs?.SCAN_WEB_URL || "https://testnet.bscscan.com";
    // eslint-disable-next-line no-undef
    window.open(`${scanWebUrl}/tx/${txHash}`, "_blank");
  };

  const redirectToMarketPlace = () => {
    const marketplaceUrl =
      configs?.REACT_APP_MARKETPLACE_URL ||
      "https://marketplace-dev.game99.xyz/";
    // eslint-disable-next-line no-undef
    window.open(marketplaceUrl, "_blank");
  };

  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center">
        {listNftStatus !== listNFTStatusOptions.LIST_TO_MARKETPLACE_SUCCESS ? (
          <div className="w-full h-auto">
            <h4 className="uppercase text-lg font-semibold mb-2 text-white">
              Price
            </h4>
            <div className="w-full h-9 flex items-center justify-center">
              {/* Select token */}
              <div className="w-36 h-[46px]">
                <SelectTokenToList
                  selected={selectedTokenObj}
                  handleSelect={setSelectedTokenObj}
                  disabled={listNftStatus !== listNFTStatusOptions.INPUT_PRICE}
                />
              </div>

              {/* Select token */}
              <div className="w-[calc(100%-144px)] h-[46px] rounded-full text-base leading-[22px] bg-white text-gray-900">
                <input
                  type="number"
                  className="w-full h-full !outline-none rounded-full pl-5 pr-4 font-semibold"
                  value={priceInput}
                  onChange={handleChangePriceInput}
                  placeholder="Enter price"
                  readOnly={listNftStatus !== listNFTStatusOptions.INPUT_PRICE}
                />
              </div>
            </div>
            {requireInputError && !priceInput ? (
              <span className="text-red-600">Price is required!</span>
            ) : null}

            <div className="w-full h-auto mt-6">
              <button
                type="button"
                onClick={handleClickBtn}
                className="w-full h-12 flex items-center justify-center rounded bg-green-main hover:bg-opacity-90"
                disabled={[
                  listNFTStatusOptions.AUTHORIZING,
                  listNFTStatusOptions.LISTING_TO_MARKETPLACE,
                ].includes(listNftStatus)}
              >
                <div className="text-lg leading-5 font-semibold text-white">
                  {getBuyBtnContent()}
                </div>
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center flex-col y-4">
            <h2 className="text-2xl uppercase">COMPLETE</h2>

            <FontAwesomeIcon icon={faCheck} className="text-green-500" />

            <div className="w-full h-auto mt-6">
              <button
                type="button"
                onClick={redirectToShowTransaction}
                className="w-full h-12 flex items-center justify-center rounded bg-green-main hover:bg-opacity-90"
              >
                <div className="text-lg leading-5 font-semibold text-white">
                  View transaction
                </div>
              </button>
            </div>
          </div>
        )}
      </div>

      <Backdrop open={showLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}

function SellTowerDialog(props) {
  const { towerToSell, handleClose, handleListSuccess } = props;

  return (
    <CustomDialog
      open={Boolean(towerToSell)}
      handleClose={handleClose}
      title="List tower nft to market."
    >
      <SellTowerDialogContent
        towerToSell={towerToSell}
        handleListSuccess={handleListSuccess}
      />
    </CustomDialog>
  );
}

export default SellTowerDialog;
