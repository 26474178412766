import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

import DefaultLayout from "app/layouts/DefaultLayout";
import rocketIcon from "app/assets/svg/rocket.svg";
import copyIcon from "app/assets/svg/copy.svg";
import twitterIcon from "app/assets/svg/twitter.svg";
import telegramIcon from "app/assets/svg/telegram.svg";
import homeBanner from "app/assets/images/home-banner.png";
import { useSelector } from "react-redux";

function HomePage() {
  const tokenAddress = useSelector(
    (state) => state.appConfigs?.configs?.GAME_TOKEN_CONTRACT_ADDRESS
  );

  const twitterLink = useSelector(
    (state) => state.appConfigs?.configs?.TWITTER_LINK
  );
  const telegramChannelLink = useSelector(
    (state) => state.appConfigs?.configs?.TELEGRAM_CHANNEL_LINK
  );
  const telegramChatLink = useSelector(
    (state) => state.appConfigs?.configs?.TELEGRAM_CHAT_LINK
  );

  return (
    <DefaultLayout>
      <div className="w-full h-auto pt-20 pb-24 bg-[#2B2B2B] text-white">
        <div className="w-full h-auto py-0 md:py-10 xl:py-20 px-4 md:px-10 xl:px-20">
          <div className="w-full h-auto flex flex-col items-center max-w-[1050px] mx-auto">
            <div className="w-full h-0 relative pt-[50%] mb-6 xl:mb-8">
              <div
                className="absolute inset-0 w-full bg-[#C4C4C4] rounded-2xl overflow-hidden"
                style={{
                  boxShadow: "rgba(255, 255, 255, 0.24) 0px 0px 8px 3px",
                }}
              >
                <img alt="" src={homeBanner} className="w-full h-full" />
              </div>
            </div>

            <h2 className="text-3xl sm:text-5xl md:text-[52px] md:leading-[62px] lg:text-[67px] lg:leading-[74px] font-semibold mb-2.5">
              Stunt Tower
            </h2>

            <h3 className="text-xl md:text-[22px] md:leading-9 font-medium mb-10">
              Token Contract
            </h3>

            <CopyToClipboard
              text={tokenAddress || ""}
              onCopy={() => toast.success("Copy")}
            >
              <div className="flex items-center justify-center px-4 md:px-8 py-2 md:py-5 rounded-lg md:rounded-[20px] bg-green-main mb-10  max-w-full overflow-hidden transition-all active:scale-95 cursor-pointer">
                <img alt="" className="w-5 h-5" src={rocketIcon} />
                <span className="text-xs md:text-[22px] md:leading-[30px] font-medium md:font-bold mx-2 md:mx-3">
                  {tokenAddress || ""}
                </span>
                <img alt="" className="w-5 h-5" src={copyIcon} />
              </div>
            </CopyToClipboard>

            <div className="w-full h-full flex items-center justify-around md:justify-center">
              <a
                href={twitterLink}
                target="_blank"
                className="w-auto h-auto flex flex-col items-center hover:bg-[#222222] rounded-lg px-2 md:px-6"
                rel="noreferrer"
              >
                <img alt="" className="w-8 h-8" src={twitterIcon} />
                <p className="text-xs md:text-base">Twitter</p>
              </a>

              <a
                href={telegramChannelLink}
                target="_blank"
                className="w-auto h-auto flex flex-col items-center hover:bg-[#222222] rounded-lg px-2 md:px-6"
                rel="noreferrer"
              >
                <img alt="" className="w-8 h-8" src={telegramIcon} />
                <p className="text-xs md:text-base">Telegram Channel</p>
              </a>

              <a
                href={telegramChatLink}
                target="_blank"
                className="w-auto h-auto flex flex-col items-center hover:bg-[#222222] rounded-lg px-2 md:px-6"
                rel="noreferrer"
              >
                <img alt="" className="w-8 h-8" src={telegramIcon} />
                <p className="text-xs md:text-base">Telegram Chat</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default HomePage;
