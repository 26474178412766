import React, { useState } from "react";
import { connectWallet } from "../../../services/web3Services";
import { useDispatch, useSelector } from "react-redux";

import metamaskLogo from "app/assets/images/metamask_logo.png";
import { getSummaryAddress } from "../../../utils";
import { Popover } from "@mui/material";
import ArrowLeft from "../../../components/icons/ArrowLeft";
import { setConnectedWalletInfo } from "../../../redux/authReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faWallet } from "@fortawesome/free-solid-svg-icons";

function HeaderConnectWalletBtn() {
  const [showDisconnectBtnEl, setShowDisconnectBtnEl] = useState(null);

  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );
  const dispatch = useDispatch();

  const handleClickWalletBtn = (e) => {
    if (connectedWalletInfo && connectedWalletInfo.address) {
      setShowDisconnectBtnEl(e.currentTarget);
    } else {
      connectWallet();
    }
  };

  const handleClickDisconnect = () => {
    dispatch(setConnectedWalletInfo(null));
    setShowDisconnectBtnEl(null);
  };

  return (
    <>
      {connectedWalletInfo && connectedWalletInfo.address ? (
        <>
          <button
            type="button"
            onClick={handleClickWalletBtn}
            className="md:hidden w-auto h-9 rounded-full pl-12 pr-2 relative bg-slate-500 ml-3"
          >
            <div className="w-12 h-12 absolute left-0 -top-1.5 rounded-full bg-gray-800 border-2 border-solid border-green-main text-green-main flex items-center justify-center">
              <FontAwesomeIcon icon={faWallet} size="xl" />
            </div>
            <div className="w-auto h-full flex items-center justify-center pl-1">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </button>

          <button
            type="button"
            onClick={handleClickWalletBtn}
            className="hidden md:flex w-auto h-14 px-8 2xl:h-[72px] 2xl:px-12 rounded-[20px] border-2 border-solid border-green-main items-center justify-center text-white"
          >
            <img
              alt="metamask"
              src={metamaskLogo}
              className="w-5 h-5 overflow-hidden mr-3"
            />
            <span className="text-[22px] leading-[30px] font-semibold mr-3">
              {getSummaryAddress(connectedWalletInfo.address)}
            </span>
            <ArrowLeft className="w-5 h-5" color="#00B800" />
          </button>
        </>
      ) : (
        <>
          <button
            type="button"
            onClick={handleClickWalletBtn}
            className="md:hidden w-auto h-9 px-2 rounded-full flex items-center justify-center text-white bg-green-main ml-3"
          >
            <span className="text-sm leading-4 font-semibold">
              Connect Your Wallet
            </span>
          </button>

          <button
            type="button"
            onClick={handleClickWalletBtn}
            className="hidden md:flex w-auto h-14 px-8 2xl:h-[72px] 2xl:px-12 rounded-[20px] border-2 border-solid border-green-main items-center justify-center text-white"
          >
            <span className="text-[22px] leading-[30px] font-semibold">
              Connect Your Wallet
            </span>
          </button>
        </>
      )}

      <Popover
        open={Boolean(showDisconnectBtnEl)}
        anchorEl={showDisconnectBtnEl}
        onClose={() => setShowDisconnectBtnEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            style: {
              width: "250px",
              height: "72px",
              borderRadius: "20px",
              backgroundColor: "#858584",
              transform: "translateY(12px)",
            },
          },
        }}
      >
        <button
          type="button"
          onClick={handleClickDisconnect}
          className="w-full h-full rounded-[20px] bg-[#858584] flex items-center justify-center text-white"
        >
          <span className="text-[22px] leading-[30px] font-semibold mr-3">
            Disconnect
          </span>
          <ArrowLeft className="w-5 h-5" color="#FFF" />
        </button>
      </Popover>
    </>
  );
}

export default HeaderConnectWalletBtn;
