export const callApiStatus = {
  idle: "idle",
  loading: "loading",
  success: "success",
  error: "error",
};

export const inventoryType = {
  inLinkedWallet: 1,
  inGame: 2,
};

export const towerType = {
  1: "Machine Tower",
  2: "Slow Tower",
  3: "Laser Tower",
  4: "Voltage Tower",
  5: "Reaper Tower",
  6: "Toxic Tower",
  7: "Canon Tower",
  8: "Tober Tower",
  9: "Tesla Tower",
  10: "Mission Tower",
};

export const defaultTowerImage =
  "https://bitechtd.s3.ap-southeast-1.amazonaws.com/ImageTower/Machine1.png";
