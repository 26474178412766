/* eslint-disable consistent-return */
import { ethers } from "ethers";
import store from "app/redux/store";
import TOKEN_ABI from "app/abis/TOKEN_ABI.json";
import NFT_ABI from "app/abis/NFT_ABI.json";
import MARKET_ABI from "app/abis/MARKET_ABI.json";
import TOWER_HOLD_IN_GAME_ABI from "app/abis/TOWER_HOLD_IN_GAME_ABI.json";

import { setConnectedWalletInfo } from "app/redux/authReducer";

export const connectWallet = async () => {
  const handleCompleteConnect = async () => {
    const provider = window.web3Provider;
    const accounts = await provider.send("eth_requestAccounts", []);

    const address = accounts[0];

    const signer = await provider.getSigner();
    await signer.signMessage("Confirm connection!");

    window.ethereum.on("accountsChanged", () => {
      store.dispatch(setConnectedWalletInfo(null));
    });

    // detect Network account change
    window.ethereum.on("networkChanged", () => {
      store.dispatch(setConnectedWalletInfo(null));
    });

    store.dispatch(
      setConnectedWalletInfo({
        address,
      })
    );
  };

  if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
    try {
      window.web3Provider = new ethers.BrowserProvider(window.ethereum);

      const currentChainId = await window.web3Provider.send("eth_chainId");

      const chainId = store.getState()?.appConfigs?.configs?.CHAIN_ID;

      if (currentChainId === chainId) {
        handleCompleteConnect();
      } else {
        try {
          await window.web3Provider.send("wallet_switchEthereumChain", [
            { chainId },
          ]);
          handleCompleteConnect();
        } catch (switchError) {
          if (switchError?.error?.code === 4902) {
            try {
              const chainInfo =
                store.getState()?.appConfigs?.configs?.CHAIN_INFO;

              await window.web3Provider.send("wallet_addEthereumChain", [
                chainInfo,
              ]);

              handleCompleteConnect();
            } catch (addError) {
              console.log(addError);
            }
          }
        }
      }
    } catch (err) {
      console.error(err.message);
    }
  } else {
    /* MetaMask is not installed */
    console.log("Please install MetaMask");
  }
};

const getWeb3Provider = () =>
  window.web3Provider
    ? window.web3Provider
    : new ethers.BrowserProvider(window.ethereum);

export const authorizeUseToken = async (
  numberOfToken,
  tokenContractAddress
) => {
  const provider = getWeb3Provider();
  const marketContractAddress =
    store.getState()?.appConfigs?.configs?.MARKET_CONTRACT_ADDRESS;

  if (!provider || !marketContractAddress || !tokenContractAddress) {
    console.log("Configs is not available!");
  } else {
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(
      tokenContractAddress,
      TOKEN_ABI,
      signer
    );

    const transaction = await contract.approve(
      marketContractAddress,
      ethers.parseUnits(numberOfToken.toString(), 18)
    );

    await transaction.wait();
  }
};

export const authorizeUseNFT = async (nftId) => {
  const provider = getWeb3Provider();
  const marketContractAddress =
    store.getState()?.appConfigs?.configs?.MARKET_CONTRACT_ADDRESS;

  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;

  if (!provider || !marketContractAddress || !nftContractAddress) {
    console.log("Configs is not available!");
  } else {
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(nftContractAddress, NFT_ABI, signer);

    const transaction = await contract.approve(marketContractAddress, nftId);

    await transaction.wait();
  }
};

export const buyNFT = async (nftId, price, tokenContractAddress) => {
  const provider = getWeb3Provider();
  const marketContractAddress =
    store.getState()?.appConfigs?.configs?.MARKET_CONTRACT_ADDRESS;

  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;

  if (!provider || !marketContractAddress || !nftContractAddress) {
    console.log("Configs is not available!");
  } else {
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(
      marketContractAddress,
      MARKET_ABI,
      signer
    );

    const parsePrice = ethers.parseEther(price.toString());
    const transaction = await contract.buy(
      nftContractAddress,
      nftId,
      parsePrice,
      tokenContractAddress
    );

    const transactionResult = await transaction.wait();
    return transactionResult;
  }
};

export const getBalance = async () => {
  const provider = window.web3Provider
    ? window.web3Provider
    : new ethers.BrowserProvider(window.ethereum);

  const contractAddress =
    store.getState()?.appConfigs?.configs?.GAME_TOKEN_CONTRACT_ADDRESS;

  const contractInfo = new ethers.Contract(
    contractAddress,
    TOKEN_ABI,
    provider
  );

  const accounts = await provider.send("eth_requestAccounts", []);
  const tokenBalance = await contractInfo.balanceOf(accounts[0]);

  return Number(ethers.formatEther(tokenBalance));
};

export const getTokenBalance = async (tokenContractAddress) => {
  const provider = window.web3Provider
    ? window.web3Provider
    : new ethers.BrowserProvider(window.ethereum);

  const contractInfo = new ethers.Contract(
    tokenContractAddress,
    TOKEN_ABI,
    provider
  );

  const accounts = await provider.send("eth_requestAccounts", []);
  const tokenBalance = await contractInfo.balanceOf(accounts[0]);

  return Number(ethers.formatEther(tokenBalance));
};

export const authorizeMarketToSellNFT = async (nftId) => {
  const provider = getWeb3Provider();
  const marketContractAddress =
    store.getState()?.appConfigs?.configs?.MARKET_CONTRACT_ADDRESS;

  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;

  if (!provider || !marketContractAddress || !nftContractAddress) {
    console.error("Configs is not available!");
  } else {
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(nftContractAddress, NFT_ABI, signer);

    const transaction = await contract.approve(marketContractAddress, nftId);
    await transaction.wait();
  }
};

export const listNft = async (nftId, tokenContractAddress, amount) => {
  const provider = getWeb3Provider();
  const marketContractAddress =
    store.getState()?.appConfigs?.configs?.MARKET_CONTRACT_ADDRESS;

  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;

  if (!provider || !marketContractAddress || !nftContractAddress) {
    console.error("Configs is not available!");
    return;
  }
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(
    marketContractAddress,
    MARKET_ABI,
    signer
  );

  const transaction = await contract.listNft(
    nftContractAddress,
    nftId,
    ethers.parseUnits(amount.toString(), 18),
    tokenContractAddress
  );

  const transactionResult = await transaction.wait();

  return transactionResult;
};

export const getListingNftInfo = async (nftId) => {
  const provider = getWeb3Provider();
  const marketContractAddress =
    store.getState()?.appConfigs?.configs?.MARKET_CONTRACT_ADDRESS;

  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;

  if (!provider || !marketContractAddress || !nftContractAddress) {
    console.error("Configs is not available!");
    return;
  }

  const contract = new ethers.Contract(
    marketContractAddress,
    MARKET_ABI,
    provider
  );

  const transactionResult = await contract.getListing(
    nftContractAddress,
    nftId
  );

  return transactionResult;
};

export const getNftOwnerInfo = async (nftId) => {
  const provider = getWeb3Provider();
  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;

  if (!provider || !nftContractAddress) {
    console.error("Configs is not available!");
    return;
  }

  const contract = new ethers.Contract(nftContractAddress, NFT_ABI, provider);

  const transactionResult = await contract.ownerOf(nftId);

  return transactionResult;
};

export const cancelListNft = async (nftId) => {
  const provider = getWeb3Provider();
  const marketContractAddress =
    store.getState()?.appConfigs?.configs?.MARKET_CONTRACT_ADDRESS;

  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;

  if (!provider || !marketContractAddress || !nftContractAddress) {
    console.error("Configs is not available!");
    return;
  }
  const signer = await provider.getSigner();
  const contract = new ethers.Contract(
    marketContractAddress,
    MARKET_ABI,
    signer
  );

  const transaction = await contract.cancelListing(nftContractAddress, nftId);

  const transactionResult = await transaction.wait();

  // eslint-disable-next-line consistent-return
  return transactionResult;
};

export const getUserNftInfo = async (nftId) => {
  const provider = getWeb3Provider();
  const towerHoldInGameAddress =
    store.getState()?.appConfigs?.configs?.TOWER_HOLD_IN_GAME_ADDRESS;

  const connectedWalletAddress =
    store.getState()?.auth?.connectedWalletInfo?.address;

  if (!provider || !towerHoldInGameAddress || !connectedWalletAddress) {
    console.error("Configs is not available!");
    return;
  }

  const contract = new ethers.Contract(
    towerHoldInGameAddress,
    TOWER_HOLD_IN_GAME_ABI,
    provider
  );

  const transactionResult = await contract.userNfts(
    connectedWalletAddress,
    nftId
  );

  // eslint-disable-next-line consistent-return
  return transactionResult;
};

export const authorizeHdWallet = async (nftId) => {
  const provider = getWeb3Provider();
  const towerHoldInGameAddress =
    store.getState()?.appConfigs?.configs?.TOWER_HOLD_IN_GAME_ADDRESS;

  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;

  if (!provider || !towerHoldInGameAddress || !nftContractAddress) {
    console.error("Configs is not available!");
  } else {
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(nftContractAddress, NFT_ABI, signer);

    const transaction = await contract.approve(towerHoldInGameAddress, nftId);

    const transactionResult = await transaction.wait();
  
    return transactionResult;
  }
};

export const depositNFTIntoGame = async (nftId) => {
  const provider = getWeb3Provider();
  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;
  const towerHoldInGameAddress =
    store.getState()?.appConfigs?.configs?.TOWER_HOLD_IN_GAME_ADDRESS;
  const userId = store.getState()?.auth?.user?.id;

  if (!provider || !nftContractAddress || !nftId || !userId) {
    console.error("Configs is not available!");
    return;
  }

  const signer = await provider.getSigner();
  const contract = new ethers.Contract(
    towerHoldInGameAddress,
    TOWER_HOLD_IN_GAME_ABI,
    signer
  );

  const transaction = await contract.depositNFTIntoGame(
    nftContractAddress,
    nftId,
    userId
  );

  const transactionResult = await transaction.wait();
  
  return transactionResult;
};

export const withdrawNFT = async (nftId) => {
  const provider = getWeb3Provider();
  const nftContractAddress =
    store.getState()?.appConfigs?.configs?.NFT_CONTRACT_ADDRESS;

  const towerHoldInGameAddress =
    store.getState()?.appConfigs?.configs?.TOWER_HOLD_IN_GAME_ADDRESS;

  const connectedWalletAddress =
    store.getState()?.auth?.connectedWalletInfo?.address;

  const userId = store.getState()?.auth?.user?.id;

  if (
    !provider ||
    !nftContractAddress ||
    !nftId ||
    !userId ||
    !connectedWalletAddress
  ) {
    console.error("Configs is not available!");
    return;
  }

  const signer = await provider.getSigner();
  const contract = new ethers.Contract(
    towerHoldInGameAddress,
    TOWER_HOLD_IN_GAME_ABI,
    signer
  );

  const transaction = await contract.withdrawNFT(
    nftContractAddress,
    nftId,
    connectedWalletAddress,
    userId
  );

  const transactionResult = await transaction.wait();

  return transactionResult;
};
