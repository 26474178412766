import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  linkedWalletInfo: null,
  token: {
    accessToken: null,
    // refreshToken: null
  },
  connectedWalletInfo: null,
  showAuthDialog: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setConnectedWalletInfo: (state, action) => {
      state.connectedWalletInfo = action.payload;
    },
    setLinkedWalletInfo: (state, action) => {
      state.linkedWalletInfo = action.payload;
    },
    setAuthLogout: (state) => {
      state.user = null;
      state.token = {
        accessToken: null,
      };
    },
    setShowAuthDialog: (state, action) => {
      state.showAuthDialog = action.payload;
    },
  },
});

export const {
  setAuthUser,
  setAuthToken,
  setConnectedWalletInfo,
  setLinkedWalletInfo,
  setAuthLogout,
  setShowAuthDialog,
} = slice.actions;

export default slice.reducer;
