import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

function LoadingBackdrop(props) {
  const { open, onClick } = props;
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: "10000" }}
      open={open}
      onClick={() => {
        if (typeof onClick === "function") onClick();
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingBackdrop;
