import React from "react";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import Path from "app/route/Path";
import HomePage from "app/pages/Home";
import WalletPage from "app/pages/Wallet";
import WalletWithdraw from "app/pages/Wallet/WithdrawPage";
import NotFound from "app/pages/NotFound";
import MarketplacePage from "app/pages/Marketplace";
import InventoryPage from "app/pages/Inventory";
import DetailTower from "../pages/DetailTower";
import ResetPassword from "../pages/auth/ResetPassword";
import VerifyEmail from "../pages/auth/VerifyEmail";

function Router() {
  const user = useSelector((state) => state.auth.user);
  const LoggedInRoutes = [
    {
      path: "/",
      element: <Navigate to={Path.home} />,
    },
    {
      path: Path.home,
      element: <HomePage />,
    },
    {
      path: Path.wallet,
      element: <WalletPage />,
    },
    {
      path: Path.walletWithdraw,
      element: <WalletWithdraw />,
    },
    {
      path: Path.marketplace,
      element: <MarketplacePage />,
    },
    {
      path: Path.inventory,
      element: <InventoryPage />,
    },

    {
      path: Path.towerDetail(),
      element: <DetailTower />,
    },

    // NotFound Page
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  const guestRoutes = [
    {
      path: "*",
      element: <Navigate to={Path.home} />,
    },
    {
      path: Path.home,
      element: <HomePage />,
    },
    {
      path: Path.marketplace,
      element: <MarketplacePage />,
    },
    {
      path: Path.forgot,
      element: <ResetPassword />,
    },
    {
      path: Path.verifyEmail,
      element: <VerifyEmail />,
    },
  ];

  const element = useRoutes(user ? LoggedInRoutes : guestRoutes);
  return element;
}

export default function AppRoute() {
  const isLoadConfigSuccess = useSelector(
    (state) => state.appConfigs.isLoadSuccess
  );

  return (
    <BrowserRouter>{isLoadConfigSuccess ? <Router /> : null}</BrowserRouter>
  );
}
