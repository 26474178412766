import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderLoginBtn from "./HeaderLoginBtn";
import HeaderConnectWalletBtn from "./HeaderConnectWalletBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import Path from "app/route/Path";

import logo from "app/assets/images/logo.png";
import logoHorizontal from "app/assets/images/logo_horizontal.png";
import { Drawer } from "@mui/material";
import { useSelector } from "react-redux";

function Header(props) {
  const { headerClassName } = props;

  const [showMobileSideMenu, setShowMobileSideMenu] = useState(false);

  const user = useSelector((state) => state.auth.user);

  return (
    <>
      {/* Mobile Header */}
      <nav
        className={`w-full h-16 px-3 bg-[#222222] text-white md:hidden ${
          headerClassName || ""
        }`}
      >
        <div className="w-full h-full flex items-center justify-between">
          <div className="w-full h-full flex-1 flex items-center">
            <button
              type="button"
              onClick={() => setShowMobileSideMenu(true)}
              className="w-8 h-8 mr-3"
            >
              <FontAwesomeIcon icon={faBars} size="lg" />
            </button>
            <div className="w-auto h-full py-1">
              <img alt="logo" src={logo} className="w-auto h-full" />
            </div>
          </div>

          {/* login btn */}
          <div className="shrink-0">
            <HeaderLoginBtn />
          </div>

          <div className="shrink-0">
            <HeaderConnectWalletBtn />
          </div>

          {/* connect wallet btn */}
        </div>
      </nav>

      {/* PC Header */}
      <nav
        className={`hidden md:block w-full h-[180px] xl:h-[298px] px-6 py-4 xl:py-[30px] bg-[#222222] text-white  ${
          headerClassName || ""
        }`}
      >
        <div className="w-full h-full flex items-center justify-between">
          <div className="flex-1 flex items-center justify-between flex-wrap xl:flex-nowrap">
            <div className="shrink-0 w-full xl:w-auto pb-4 xl:pb-0">
              <HeaderLoginBtn />
            </div>

            <div className="flex-1 flex items-center justify-around">
              <Link
                to={Path.home}
                className="text-[22px] leading-[30px] font-semibold group py-2 mb-2 flex"
              >
                <div className="w-auto h-auto relative">
                  Home
                  <div
                    className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                  />
                </div>
              </Link>

              {user ? (
                <Link
                  to={Path.wallet}
                  className="text-[22px] leading-[30px] font-semibold group py-2 mb-2 flex"
                >
                  <div className="w-auto h-auto relative">
                    Wallet
                    <div
                      className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                    />
                  </div>
                </Link>
              ) : (
                <Link className="text-[22px] leading-[30px] font-semibold group py-2 mb-2 flex pointer-events-none">
                  <p className="w-auto h-auto relative text-[#858584]">
                    Wallet
                  </p>
                </Link>
              )}
            </div>
          </div>
          {/* login btn */}

          <Link to={Path.home} className="block w-auto h-full shrink-0">
            <img alt="" src={logo} className="w-full h-full" />
          </Link>

          <div className="flex-1 flex items-center justify-between flex-wrap xl:flex-nowrap xl:flex-row-reverse">
            <div className="shrink-0 w-full xl:w-auto flex justify-end pb-4 xl:pb-0">
              <HeaderConnectWalletBtn />
            </div>

            <div className="flex-1 flex items-center justify-around">
              <Link
                to={Path.marketplace}
                className="text-[22px] leading-[30px] font-semibold group py-2 mb-2 flex"
              >
                <div className="w-auto h-auto relative">
                  Marketplace
                  <div
                    className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                  />
                </div>
              </Link>

              {user ? (
                <Link
                  to={Path.inventory}
                  className="text-[22px] leading-[30px] font-semibold group py-2 mb-2 flex"
                >
                  <div className="w-auto h-auto relative">
                    Inventory
                    <div
                      className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                    />
                  </div>
                </Link>
              ) : (
                <Link className="text-[22px] leading-[30px] font-semibold group py-2 mb-2 flex  pointer-events-none">
                  <div className="w-auto h-auto relative  text-[#858584]">
                    Inventory
                    <div
                      className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                    />
                  </div>
                </Link>
              )}
            </div>
          </div>

          {/* connect wallet btn */}
        </div>
      </nav>

      <Drawer
        anchor="left"
        open={showMobileSideMenu}
        onClose={() => setShowMobileSideMenu(false)}
        slotProps={{
          backdrop: {
            classes: {
              root: " bg-opacity-40",
            },
          },
        }}
      >
        <div className="w-screen sm:w-[320px] h-screen">
          <div className="w-full h-16 bg-[#222222] relative py-1 flex justify-center">
            <Link to={Path.home} className="block w-auto h-full">
              <img alt="" src={logoHorizontal} className="w-auto h-full" />
            </Link>
            <button
              type="button"
              onClick={() => setShowMobileSideMenu(false)}
              className="w-8 h-8 absolute right-3 top-1/2 -translate-y-1/2 text-white"
            >
              <FontAwesomeIcon icon={faXmark} size="2x" />
            </button>
          </div>

          <div className="w-full h-[calc(100%-64px)] p-6 bg-[#2B2B2B] text-white">
            <div className="w-full h-full overflow-x-hidden overflow-y-auto flex flex-col">
              <Link
                to={Path.home}
                className="group w-full text-xl font-semibold py-2 mb-2 flex"
              >
                <div className="w-auto h-auto relative">
                  Home
                  <div
                    className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                  />
                </div>
              </Link>

              {user ? (
                <Link
                  to={Path.wallet}
                  className="group w-full text-xl font-semibold py-2 mb-2 flex"
                >
                  <div className="w-auto h-auto relative">
                    Wallet
                    <div
                      className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                    />
                  </div>
                </Link>
              ) : (
                <Link className="group w-full text-xl font-semibold py-2 mb-2 flex pointer-events-none">
                  <div className="w-auto h-auto relative text-[#858584]">
                    Wallet
                  </div>
                </Link>
              )}

              <Link
                to={Path.marketplace}
                className="group w-full text-xl font-semibold py-2 mb-2 flex"
              >
                <div className="w-auto h-auto relative">
                  Marketplace
                  <div
                    className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                  />
                </div>
              </Link>

              {user ? (
                <Link
                  to={Path.inventory}
                  className="group w-full text-xl font-semibold py-2 mb-2 flex"
                >
                  <div className="w-auto h-auto relative">
                    Inventory
                    <div
                      className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                    />
                  </div>
                </Link>
              ) : (
                <Link
                  to={Path.inventory}
                  className="group w-full text-2xl font-semibold py-2 mb-2 flex pointer-events-none"
                >
                  <div className="w-auto h-auto relative text-[#858584]">
                    Inventory
                    <div
                      className={`absolute inset-x-0 bottom-0 w-1/4 transition-all h-[2px] bg-white bg-opacity-0 group-hover:bg-opacity-100 group-hover:w-full`}
                    />
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Header;
