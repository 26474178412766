import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getDetailTower } from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import LoadingProgress from "app/components/CommonUI/LoadingProgress";
import LoadingFail from "app/components/CommonUI/LoadingFail";
import { defaultTowerImage } from "app/constants";

import damageIcon from "app/assets/svg/statIcon/damage.svg";
import fireRate from "app/assets/svg/statIcon/fireRate.svg";
import slowSpeed from "app/assets/svg/statIcon/slowSpeed.svg";
import speed from "app/assets/svg/statIcon/speed.svg";
import starIcon from "app/assets/svg/tower_star_icon.svg";
import TowerNftStatusAndAction from "./TowerNftStatusAndAction";
import SaleHistory from "./SaleHistory";
import RelatedTower from "./RelatedTower";

function DetailTowerContent(props) {
  const { index, towerInfo } = props;

  const towerInfoSummary = useMemo(() => {
    const result = {
      id: towerInfo._id,
      index: towerInfo.index,
      name: towerInfo.name,
      image: defaultTowerImage,
      fire: 0,
      damage: 0,
      slow_speed: 0,
      speed: 0,
    };

    const asset = towerInfo.asset;
    result.image = asset?.url || defaultTowerImage;

    if (Array.isArray(towerInfo.levels) && towerInfo.levels.length > 0) {
      const towerInfoLevel0 = towerInfo.levels[0];
      result.fire = Number(towerInfoLevel0.fire_rate);
      result.damage = Number(towerInfoLevel0.damage);
      result.slow_speed = Number(towerInfoLevel0.slow_speed);
      result.speed = Number(towerInfoLevel0.speed);
    }

    return result;
  }, [towerInfo]);

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex">
        <div className="w-1/3">
          <div className="aspect-square bg-[#C4C4C4]">
            <img
              alt=""
              className="w-full h-full object-contain"
              src={towerInfoSummary.image}
            />
          </div>
        </div>

        <div className="w-2/3 pl-7">
          <div className="w-full h-auto">
            <div className="w-full h-auto mb-3">
              <p className="font-space_mono text-[22px] font-bold leading-9 mb-7">
                For sale #{index}
              </p>

              <h2 className="font-work_sans font-semibold text-[36px] leading-[45px]">
                {towerInfo.name}
              </h2>
              <div className="flex items-center">
                <img alt="" src={starIcon} className="w-6 h-6" />
              </div>
            </div>
            <div className="w-full h-auto">
              <TowerNftStatusAndAction
                ownerAccountId={towerInfo.owner}
                nftToken={Number(index)}
                towerInfo={towerInfo}
              />
            </div>

            <div className="pl-5 mb-5">
              <div className="flex">
                <h2 className="w-auto h-[60px] border-b-2 border-[#00B800] flex items-center justify-center mb-5">
                  <span className="font-work_sans text-[22px] leading-8 font-semibold">
                    About
                  </span>
                </h2>
              </div>

              <div className="flex items-center">
                <div className="w-1/4 max-w-[120px]">
                  <div className="w-full h-5 flex items-center gap-[5px] mb-2">
                    <img alt="" className="w-5 h-5" src={damageIcon} />
                    <span className="text-xs text-[#858585] font-space_mono">
                      Damage
                    </span>
                  </div>
                  <div className="w-full text-sm">
                    {towerInfoSummary.damage}
                  </div>
                </div>
                <div className="w-1/4 max-w-[120px]">
                  <div className="w-full h-5 flex items-center gap-[5px] mb-2">
                    <img alt="" className="w-5 h-5" src={fireRate} />
                    <span className="text-xs text-[#858585] font-space_mono">
                      Fire Rate
                    </span>
                  </div>
                  <div className="w-full text-sm">{towerInfoSummary.fire}</div>
                </div>
                <div className="w-1/4 max-w-[120px]">
                  <div className="w-full h-5 flex items-center gap-[5px] mb-2">
                    <img alt="" className="w-5 h-5" src={slowSpeed} />
                    <span className="text-xs text-[#858585] font-space_mono">
                      Slow Speed
                    </span>
                  </div>
                  <div className="w-full text-sm">
                    {towerInfoSummary.slow_speed}
                  </div>
                </div>
                <div className="w-1/4 max-w-[120px]">
                  <div className="w-full h-5 flex items-center gap-[5px] mb-2">
                    <img alt="" className="w-5 h-5" src={speed} />
                    <span className="text-xs text-[#858585] font-space_mono">
                      Speed
                    </span>
                  </div>
                  <div className="w-full text-sm">{towerInfoSummary.speed}</div>
                </div>
              </div>
            </div>

            <div className="pl-5 mb-5">
              <div className="flex">
                <h2 className="w-auto h-[60px] border-b-2 border-[#00B800] flex items-center justify-center mb-5">
                  <span className="font-work_sans text-[22px] leading-8 font-semibold">
                    Sale History
                  </span>
                </h2>
              </div>

              <div>
                <SaleHistory towerIndex={index} towerId={towerInfo.id} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-auto">
        <RelatedTower towerIndex={index} id={towerInfo.id} />
      </div>
    </div>
  );
}

function DetailTower() {
  const { index } = useParams();

  const [loadDataState, setLoadDataState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadDataState((oldState) => ({
      ...oldState,
      isLoading: true,
      error: null,
    }));

    getDetailTower(index)
      .then((res) => {
        setLoadDataState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error("Load data fail!");
        setLoadDataState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [index]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <DefaultLayout>
      <div className="w-full h-auto pt-5 pb-20 px-0 bg-[#2B2B2B] text-white">
        <div className="custom-container-medium">
          {loadDataState.isLoading ? <LoadingProgress /> : null}

          {!loadDataState.isLoading && loadDataState.error ? (
            <LoadingFail error={loadDataState.error} />
          ) : null}

          {!loadDataState.isLoading &&
          !loadDataState.error &&
          loadDataState.data ? (
            <DetailTowerContent
              index={Number(index)}
              towerInfo={loadDataState.data}
            />
          ) : null}
        </div>
      </div>
    </DefaultLayout>
  );
}

export default DetailTower;
