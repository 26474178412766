import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DefaultLayout from "app/layouts/DefaultLayout";
import CommonPagination from "app/components/CommonUI/CommonPagination";
import LoadingProgress from "app/components/CommonUI/LoadingProgress";
import LoadingFail from "app/components/CommonUI/LoadingFail";
import NoData from "app/components/CommonUI/NoData";
import { ConfirmLinkWallet } from "app/components/LoggedInMenu";
import LoadingBackdrop from "app/components/CommonUI/LoadingBackdrop";
import CardTower from "app/components/Card/CardTower";
import { setLinkedWalletInfo, setShowAuthDialog } from "app/redux/authReducer";
import {
  getInWalletInventory,
  getInGameInventory,
  linkWallet,
} from "app/services/api";

import emptyFolder from "app/assets/images/empty-folder.png";
import { connectWallet } from "../../services/web3Services";

function LoginToSeeData(props) {
  const { handleShowAuthDialog } = props;
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="w-24 h-24 mb-6">
        <img
          src={emptyFolder}
          alt="required connect wallet"
          className="w-full h-full"
        />
      </div>

      <p className="text-base font-semibold text-gray-300 mb-6">
        Please login to see the data
      </p>

      <button
        type="button"
        onClick={handleShowAuthDialog}
        className="w-auto h-9 px-2 rounded-full flex items-center justify-center text-white bg-green-main ml-3"
      >
        <span className="text-sm leading-4 font-semibold">Login</span>
      </button>
    </div>
  );
}

function LinkWalletToSeeData(props) {
  const { connectedWalletInfo, user } = props;
  const [confirmLinkWallet, setConfirmLinkWallet] = useState(false);
  const [isCallingApi, setIsCallingApi] = useState(false);

  const dispatch = useDispatch();

  const handleLinkWallet = () => {
    if (!connectedWalletInfo || !connectedWalletInfo.address) {
      toast.error(
        <div className="flex flex-col">
          <p className="font-bold mb-1">Error</p>
          <p>Please connect your wallet first!</p>
        </div>
      );
      return;
    }

    setIsCallingApi(true);
    linkWallet(connectedWalletInfo.address)
      .then((res) => {
        dispatch(setLinkedWalletInfo({ address: connectedWalletInfo.address }));
        setConfirmLinkWallet(false);
        toast.success(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Success</p>
            <p>Link wallet successful!</p>
          </div>
        );
      })
      .catch((error) => {
        toast.error(
          <div className="flex flex-col">
            <p className="font-bold mb-1">Error</p>
            <p>Link wallet failed!</p>
          </div>
        );
      })
      .finally(() => setIsCallingApi(false));
  };

  const handleClickLinkWallet = () => {
    if (!connectedWalletInfo) {
      toast.error(
        <div className="flex flex-col">
          <p className="font-bold mb-1">Error</p>
          <p>Please connect your wallet first!</p>
        </div>
      );
      return;
    }

    setConfirmLinkWallet(true);
  };

  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-24 h-24 mb-6">
          <img
            src={emptyFolder}
            alt="required connect wallet"
            className="w-full h-full"
          />
        </div>

        <p className="text-base font-semibold text-gray-300 mb-6">
          Please link wallet to see data
        </p>

        <button
          type="button"
          onClick={handleClickLinkWallet}
          className="w-auto h-9 px-2 rounded-full flex items-center justify-center text-white bg-green-main ml-3"
        >
          <span className="text-sm leading-4 font-semibold">Link wallet</span>
        </button>
      </div>

      <ConfirmLinkWallet
        open={Boolean(confirmLinkWallet && connectedWalletInfo && user)}
        handleClose={() => setConfirmLinkWallet(false)}
        handleLinkWallet={handleLinkWallet}
      />

      <LoadingBackdrop open={isCallingApi} />
    </>
  );
}

function InWalletInventory(props) {
  const { connectedWalletInfoAddress } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loadDataState, setLoadDataState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInWalletInventory = useCallback(() => {
    setLoadDataState((oldState) => ({
      ...oldState,
      isLoading: true,
      error: null,
    }));

    getInWalletInventory(connectedWalletInfoAddress, currentPage)
      .then((res) => {
        if (Number(res.data.totalPages) > 0 && Number(res.data.total) > 0)
          setTotalPages(res.data.totalPages);

        setLoadDataState({
          isLoading: false,
          data: Array.isArray(res.data.rows) ? res.data.rows : [],
          error: null,
        });
      })
      .catch((error) => {
        toast.error("Load data fail!");
        setLoadDataState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [connectedWalletInfoAddress, currentPage]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    loadInWalletInventory();
  }, [loadInWalletInventory]);

  return (
    <div className="w-full h-auto flex justify-center flex-wrap gap-[30px] mb-12">
      {loadDataState.isLoading ? <LoadingProgress /> : null}

      {!loadDataState.isLoading && loadDataState.error ? (
        <LoadingFail
          error={loadDataState.error}
          errorHandling={loadInWalletInventory}
        />
      ) : null}

      {!loadDataState.isLoading &&
      !loadDataState.error &&
      Array.isArray(loadDataState.data) ? (
        loadDataState.data.length > 0 ? (
          loadDataState.data.map((item) => (
            <CardTower key={item._id} towerInfo={item} />
          ))
        ) : (
          <NoData />
        )
      ) : null}
      {totalPages > 0 ? (
        <div className="w-full h-auto flex item-center justify-center">
          <CommonPagination
            page={currentPage}
            onChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      ) : null}
    </div>
  );
}

function InGameInventory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  useState(null);

  const [loadDataState, setLoadDataState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInGameInventory = useCallback(() => {
    setLoadDataState((oldState) => ({
      ...oldState,
      isLoading: true,
      error: null,
    }));

    getInGameInventory(currentPage)
      .then((res) => {
        if (Number(res.data.totalPages) > 0 && Number(res.data.total) > 0)
          setTotalPages(res.data.totalPages);

        setLoadDataState({
          isLoading: false,
          data: Array.isArray(res.data.rows) ? res.data.rows : [],
          error: null,
        });
      })
      .catch((error) => {
        toast.error("Load data fail!");
        setLoadDataState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [currentPage]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    loadInGameInventory();
  }, [loadInGameInventory]);

  return (
    <div className="w-full h-auto flex justify-center flex-wrap gap-[30px] mb-12">
      {loadDataState.isLoading ? <LoadingProgress /> : null}

      {!loadDataState.isLoading && loadDataState.error ? (
        <LoadingFail
          error={loadDataState.error}
          errorHandling={loadInGameInventory}
        />
      ) : null}

      {!loadDataState.isLoading &&
      !loadDataState.error &&
      Array.isArray(loadDataState.data) ? (
        loadDataState.data.length > 0 ? (
          loadDataState.data.map((item) => (
            <CardTower key={item._id} towerInfo={item} />
          ))
        ) : (
          <NoData />
        )
      ) : null}
      {totalPages > 0 ? (
        <div className="w-full h-auto flex item-center justify-center">
          <CommonPagination
            page={currentPage}
            onChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      ) : null}
    </div>
  );
}

const inventoryTypeOptions = {
  inWallet: {
    label: "In Wallet",
    key: "in-wallet",
  },
  inGameTower: {
    label: "In Game Tower",
    key: "in-game-tower",
  },
};
function InventoryPage() {
  const [inventoryType, setInventoryType] = useState(
    inventoryTypeOptions.inWallet.key
  );

  const dispatch = useDispatch();
  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );
  const hasLoggedIn = useSelector((state) => state.auth.token?.accessToken);
  const user = useSelector((state) => state.auth.user);
  const linkedWalletInfo = useSelector((state) => state.auth?.linkedWalletInfo);

  const handleShowAuthDialog = () => {
    dispatch(setShowAuthDialog(true));
  };

  return (
    <DefaultLayout>
      <div className="w-full h-auto pt-5 pb-20 px-0 bg-[#2B2B2B] text-white">
        <div className="custom-container-medium">
          <h1 className="text-[32px] leading-[40px] md:text-[40px] md:leading-[46px] lg:text-[44px] lg:leading-[50px] font-semibold mb-12">
            Inventory
          </h1>

          <div className="w-full h-[60px] flex items-center mb-12">
            <button
              type="button"
              onClick={() =>
                setInventoryType(inventoryTypeOptions.inWallet.key)
              }
              className={`h-full px-5 md:px-[30px] flex items-center justify-center relative text-[#858584] hover:text-white ${
                inventoryType === inventoryTypeOptions.inWallet.key
                  ? "!text-white"
                  : ""
              }`}
            >
              <span className="text-lg md:text-[22px] md:leading-[30px] font-semibold">
                {inventoryTypeOptions.inWallet.label}
              </span>
              <div
                className={`absolute left-1/2 bottom-0 -translate-x-1/2 h-0.5 bg-[#00B800] transition-all duration-300 ${
                  inventoryType === inventoryTypeOptions.inWallet.key
                    ? "opacity-100 w-full"
                    : "opacity-0 w-1/4"
                }`}
              />
            </button>
            <button
              type="button"
              onClick={() =>
                setInventoryType(inventoryTypeOptions.inGameTower.key)
              }
              className={`h-full px-5 md:px-[30px] flex items-center justify-center relative text-[#858584] hover:text-white ${
                inventoryType === inventoryTypeOptions.inGameTower.key
                  ? "!text-white"
                  : ""
              }`}
            >
              <span className="text-lg md:text-[22px] md:leading-[30px] font-semibold">
                {inventoryTypeOptions.inGameTower.label}
              </span>
              <div
                className={`absolute left-1/2 bottom-0 -translate-x-1/2 h-0.5 bg-[#00B800] transition-all duration-300 ${
                  inventoryType === inventoryTypeOptions.inGameTower.key
                    ? "opacity-100 w-full"
                    : "opacity-0 w-1/4"
                }`}
              />
            </button>
          </div>

          {inventoryType === inventoryTypeOptions.inGameTower.key ? (
            !user || !hasLoggedIn ? (
              <LoginToSeeData handleShowAuthDialog={handleShowAuthDialog} />
            ) : (
              <InGameInventory />
            )
          ) : null}

          {inventoryType === inventoryTypeOptions.inWallet.key ? (
            !connectedWalletInfo?.address ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <div className="w-24 h-24 mb-6">
                  <img
                    src={emptyFolder}
                    alt="required connect wallet"
                    className="w-full h-full"
                  />
                </div>

                <p className="text-base font-semibold text-gray-300 mb-6">
                  Please connect wallet to see data
                </p>

                <button
                  type="button"
                  onClick={connectWallet}
                  className="w-auto h-9 px-2 rounded-full flex items-center justify-center text-white bg-green-main ml-3"
                >
                  <span className="text-sm leading-4 font-semibold">
                    Connect wallet
                  </span>
                </button>
              </div>
            ) : (
              <InWalletInventory
                connectedWalletInfoAddress={connectedWalletInfo?.address}
              />
            )
          ) : null}
        </div>
      </div>
    </DefaultLayout>
  );
}

export default InventoryPage;
