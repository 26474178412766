import React from "react";

function ArrowLeft(props) {
  const { color, className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      className={className || ""}
    >
      <path
        fill={color || "#FFF"}
        fillRule="evenodd"
        d="M3 10c0-.345.28-.625.625-.625h13.75a.625.625 0 110 1.25H3.625A.625.625 0 013 10z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color || "#FFF"}
        fillRule="evenodd"
        d="M9.692 3.933a.625.625 0 010 .884L4.509 10l5.183 5.183a.625.625 0 11-.884.884l-5.625-5.625a.625.625 0 010-.884l5.625-5.625a.625.625 0 01.884 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ArrowLeft;
