import React from "react";
import { callApiStatus } from "../../constants";
import { getUserProfile } from "../../services/api";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DefaultLayout from "app/layouts/DefaultLayout";
import errorAvatar from "app/assets/images/categoryImages/category_image_2.png";
import metamaskLogo from "app/assets/images/metamask_logo.png";
import withdrawIcon from "app/assets/svg/withdraw.svg";
import walletBanner from "app/assets/images/wallet-banner.png";

import Path from "app/route/Path";
import { getSummaryAddress } from "../../utils";
import { getBalance } from "../../services/web3Services";

function WalletBalance() {
  const gameTokenSymbol = useSelector(
    (state) => state.appConfigs?.configs?.GAME_TOKEN_SYMBOL
  );

  const [getBalanceStatus, setGetBalanceStatus] = useState(callApiStatus.idle);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    setGetBalanceStatus(callApiStatus.loading);

    getBalance()
      .then((res) => {
        setBalance(res);
        setGetBalanceStatus(callApiStatus.success);
      })
      .catch((error) => {
        setGetBalanceStatus(callApiStatus.error);
      });
  }, []);

  return (
    <div className="w-auto flex flex-col mr-8">
      <p className="text-[20px] md:text-[24px] leading-10 font-bold !font-space_mono">
        {getBalanceStatus === callApiStatus.loading ? (
          <Skeleton sx={{ bgcolor: "colors.white.main" }} width={100} />
        ) : getBalanceStatus === callApiStatus.success ? (
          <span>{balance}</span>
        ) : null}
      </p>
      <p className="text-[16px] md:text-[20px] left-9 font-normal text-transform: capitalize">
        {gameTokenSymbol}
      </p>
    </div>
  );
}

function WalletPage() {
  const [getProfileStatus, setGetProfileStatus] = useState(callApiStatus.idle);
  const [userProfile, setUserProfile] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const linkedWalletInfo = useSelector((state) => state.auth.linkedWalletInfo);
  const connectedWalletInfo = useSelector(
    (state) => state.auth.connectedWalletInfo
  );

  useEffect(() => {
    if (user && userProfile === null) {
      setGetProfileStatus(callApiStatus.loading);

      getUserProfile()
        .then((res) => {
          setUserProfile(res.data);
          setGetProfileStatus(callApiStatus.success);
        })
        .catch((error) => {
          setGetProfileStatus(callApiStatus.error);
        });
    }
  }, [user, userProfile]);

  const errorNetwork = "Error400";
  const errorServer = "Error500";

  return (
    <DefaultLayout>
      <div className="w-full h-auto pb-24 bg-[#2B2B2B] text-white">
        <div className="w-full h-auto pb-8 md:pb-12 lg:mb-[60px]">
          <div className="w-full h-0 pt-[56%] md:pt-[42%] relative">
            <div
              className="absolute inset-0 w-full h-full overflow-hidden"
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 21.88%, rgba(0, 184, 0, 1) 95.31%), linear-gradient(0deg, #C4C4C4, #C4C4C4)",
              }}
            >
              <img
                alt=""
                src={walletBanner}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full h-16 md:h-24 lg:h-[120px] absolute inset-x-0 left-0 -bottom-8 md:-bottom-12 lg:-bottom-[60px]">
              <div className="custom-container-small">
                <div className="w-16 h-16 md:w-24 md:h-24 lg:w-[120px] lg:h-[120px] rounded-xl lg:rounded-[20px] overflow-hidden">
                  {getProfileStatus === callApiStatus.success &&
                  userProfile &&
                  userProfile.avatar !== "" ? (
                    <img
                      alt=""
                      src={userProfile.avatar}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <img
                      alt=""
                      src={errorAvatar}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-auto py-10">
          <div className="custom-container-small">
            <div className="w-full h-auto flex justify-between items-center flex-wrap">
              <h2 className="text-[32px] leading-9 lg:text-[44px] lg:leading-[56px] font-semibold mb-4">
                {getProfileStatus === callApiStatus.loading ||
                getProfileStatus === callApiStatus.idle ? (
                  <Skeleton sx={{ bgcolor: "colors.white.main" }} width={100} />
                ) : getProfileStatus === callApiStatus.error ? (
                  errorNetwork
                ) : getProfileStatus === callApiStatus.success &&
                  userProfile ? (
                  userProfile.name ? (
                    userProfile.name
                  ) : userProfile.email ? (
                    userProfile.email?.split("@")?.[0]
                  ) : (
                    ""
                  )
                ) : (
                  errorServer
                )}
              </h2>
              <div className="w-auto h-auto flex items-center justify-end mb-4">
                {connectedWalletInfo && connectedWalletInfo.address ? (
                  <button
                    type="button"
                    className="flex items-center justify-center mr-5 w-auto h-[46px] px-4 rounded-xl lg:h-[60px] lg:px-12 lg:rounded-[20px] bg-green-main "
                  >
                    <img alt="" className="w-5 h-5 mr-3" src={metamaskLogo} />

                    <span>
                      {getSummaryAddress(connectedWalletInfo.address)}
                    </span>
                  </button>
                ) : null}

                <Link
                  to={Path.walletWithdraw}
                  className="flex items-center justify-center w-auto h-[46px] px-4 rounded-xl lg:h-[60px] lg:px-12 lg:rounded-[20px] border-2 border-solid border-green-main"
                >
                  <img alt="" className="w-5 h-5 mr-3" src={withdrawIcon} />

                  <span>Withdraw</span>
                </Link>
              </div>
            </div>

            <div className="w-full h-auto flex items-center mb-[30px]">
              {connectedWalletInfo?.address ? <WalletBalance /> : null}

              <div className="w-auto flex flex-col mr-8">
                <p className="text-[20px] md:text-[24px] leading-10 font-bold !font-space_mono">
                  {getProfileStatus === callApiStatus.loading ||
                  getProfileStatus === callApiStatus.idle ? (
                    <Skeleton
                      sx={{ bgcolor: "colors.white.main" }}
                      width={100}
                    />
                  ) : getProfileStatus === callApiStatus.error ? (
                    errorNetwork
                  ) : getProfileStatus === callApiStatus.success &&
                    userProfile &&
                    userProfile?.items ? (
                    userProfile.items.length
                  ) : (
                    errorServer
                  )}
                </p>
                <p className="text-[16px] md:text-[20px] left-9 font-normal text-transform: capitalize">
                  NFTs Tower
                </p>
              </div>

              <div className="w-auto flex flex-col">
                <p className="text-[20px] md:text-[24px] leading-10 font-bold !font-space_mono">
                  {getProfileStatus === callApiStatus.loading ||
                  getProfileStatus === callApiStatus.idle ? (
                    <Skeleton
                      sx={{ bgcolor: "colors.white.main" }}
                      width={100}
                    />
                  ) : getProfileStatus === callApiStatus.error ? (
                    errorNetwork
                  ) : getProfileStatus === callApiStatus.success &&
                    userProfile &&
                    userProfile?.wallets[0]?.amount >= 0 ? (
                    userProfile.wallets[0].amount
                  ) : (
                    errorServer
                  )}
                </p>
                <p className="text-[16px] md:text-[20px] left-9 font-normal text-transform: capitalize">
                  {getProfileStatus === callApiStatus.loading ||
                  getProfileStatus === callApiStatus.idle ? (
                    <Skeleton
                      sx={{ bgcolor: "colors.white.main" }}
                      width={100}
                    />
                  ) : getProfileStatus === callApiStatus.error ? (
                    errorNetwork
                  ) : getProfileStatus === callApiStatus.success &&
                    userProfile &&
                    userProfile?.wallets[0]?.symbol ? (
                    <span className="uppercase">
                      {userProfile.wallets[0].symbol}
                    </span>
                  ) : (
                    errorServer
                  )}
                </p>
              </div>
            </div>

            <div className="w-full h-auto mb-[30px]">
              <h3 className="text-[22px] leading-9 font-bold text-[#858584] mb-2.5 !font-space_mono">
                Notes
              </h3>
              <div className="w-full h-auto font-work_sans text-[15px] md:text-base leading-[22px] max-w-[600px]">
                <p className="">
                  - At this soft launch phase, users will pay the withdrawal fee
                  10% of the total DIAMOND amount.{" "}
                </p>
                <p className="">
                  - All of DIAMOND from withdrawal fee will be burned.
                </p>
                <p className="">
                  - Users can withdraw all of the DIAMOND amount in the account
                  in each withdrawal request. .
                </p>
                <p className="">
                  - Users can withdraw DIAMOND every 7 days. After each
                  withdrawal request, DIAMOND will be paid to user's wallet in
                  the next day from 3:00 - 11:00 UTC.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default WalletPage;
