import React from "react";
import searchTowerIcon from "app/assets/svg/search_tower_icon.svg";

function SearchText(props) {
  const { search, handleSearchTextChange } = props;

  const onChange = (e) => {
    handleSearchTextChange(e.target.value);
  };
  return (
    <div className="relative mr-6 w-full max-w-[360px]">
      <input
        type="text"
        value={search}
        onChange={onChange}
        className="w-full h-[56px] pr-16 pl-4 py-3 xl:h-[72px] xl:pr-16 xl:pl-12 xl:py-4 text-[18px] xl:text-[22px] font-semibold leading-8 border-2 border-solid border-green-main rounded-[20px] bg-[#2b2b2b]"
        placeholder="NFT Tower Id"
      ></input>

      <img
        alt=""
        className="w-8 h-8 absolute right-0 top-1/2 -translate-y-4 -translate-x-6"
        src={searchTowerIcon}
      />
    </div>
  );
}

export default SearchText;
