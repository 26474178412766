import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    colors: {
      purple: {
        main: "#00B800",
      },
      white: {
        main: "#ffffff",
      },
    },
  },
});

export default theme;
