import axios from "axios";
import store from "app/redux/store";
import { toast } from "react-toastify";
import { setAuthLogout } from "../../redux/authReducer";

const client = axios.create({
  timeout: 30000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

client.interceptors.request.use(
  (config) => {
    config.baseURL = store.getState()?.appConfigs?.configs?.REACT_APP_API_URL;

    const token = store.getState()?.auth?.token?.accessToken || "";

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

client.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.response.status === 401) store.dispatch(setAuthLogout());
    else if (
      err.response.status >= 500 &&
      err.response.data.message !== "Token expired"
    ) {
      toast.error("Failed to fetch!");
    }

    return Promise.reject(err);
  }
);

export default client;
